import { Box } from '@mui/material';
import { TableColumn } from 'generated/graphql';

const ExpandableTableColumnTags = ({
  column,
  onTagClick,
}: {
  column: TableColumn;
  onTagClick: (url: string) => void;
}) => {
  const { tags, align: textAlign } = column;
  if (tags.length < 0) return null;
  return (
    <>
      {tags.map((tag, i) => {
        return (
          <Box
            key={i}
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(12), // TODO: Investigate why giving error when using theme instead of baseTheme
              width: 'max-content',
              fontWeight: theme.typography.fontWeightLight ?? 'inherit', // TODO: Investigate why giving error when using theme instead of baseTheme
              display: 'table-cell',
              maxWidth: theme.spacing(12),
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              textAlign: textAlign as string,
              '&:hover': {
                fontWeight: theme.typography.fontWeightBold, // TODO: Investigate why giving error when using theme instead of baseTheme
              },
            })}
            onClick={() => onTagClick(tag.actions[0].url)}
          >
            {tag?.label ?? ''}
          </Box>
        );
      })}
    </>
  );
};

export default ExpandableTableColumnTags;
