import { Theme } from '@mui/material';

const robotoMono = [
  'Roboto Mono',
  'courier',
  'Roboto',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'sans-serif',
].join(',');

const componentThemeOverrides = (theme: Theme): Theme['components'] => {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.4,
          letterSpacing: '0.01em',
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 800,
          minWidth: 32,
          fontSize: '0.85rem',
          padding: theme.spacing(0.625, 1.25),
          borderRadius: theme.spacing(1.25),
          textTransform: 'none',
        },
        outlined: {
          padding: theme.spacing(0.625, 1.25),
        },
        sizeSmall: {
          fontWeight: 700,
          fontSize: '0.80rem',
          padding: theme.spacing(0.25, 1.5),
          borderRadius: theme.spacing(0.75),
        },
        sizeLarge: {
          fontSize: '1.1rem',
          padding: theme.spacing(1.2, 4.2),
        },
        containedSecondary: {
          color: '#fff',
        },
      },
    },
    MuiToolbar: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        regular: {
          minHeight: 56,
          padding: '0 2rem 0 1rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0, 1.75),
        },
        standardInfo: {
          color: theme.palette.primary.dark,
          backgroundColor: theme.palette.tertiary.light,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 700,
          '&.Mui-selected': {
            color: theme.palette.common.black,
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          fontFamily: theme.typography.h1.fontFamily,
        },
        underlineHover: {
          '&:hover': {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${theme.palette.divider}`,
          overflow: 'hidden',
          position: 'fixed',
          height: 'calc(56px + env(safe-area-inset-bottom))',
          width: '100%',
          bottom: 0,
          padding: '0 0 env(safe-area-inset-bottom) 0',
          zIndex: 10,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
          minWidth: theme.spacing(4),
          '&.Mui-selected': {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          color: '#fff',
          background: theme.palette.primary.light,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: 'none',
          zIndex: 100,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: theme.palette.primary.light,
          '&.MuiChip-outlinedPrimary': {
            backgroundColor: 'inherit',
            color: theme.palette.primary.light,
            borderColor: theme.palette.primary.light,
          },
        },
        label: {
          fontWeight: 700,
          paddingLeft: theme.spacing(1.25),
          paddingRight: theme.spacing(1.25),
        },
        clickable: {
          '&:hover, &:focus': {
            backgroundColor: '', // Remove default grey on all clickable items
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: 0,
          minWidth: 300,
          [theme.breakpoints.down('md')]: {
            width: '100%',
            borderRadius: theme.spacing(1.5, 1.5, 0, 0),
          },
        },
        scrollPaper: {
          [theme.breakpoints.down('md')]: {
            alignItems: 'flex-end',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2, 3, 0),
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: '250px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: robotoMono,
          fontSize: '0.7rem',
        },
        head: {
          fontFamily: theme.typography.fontFamily,
          color: theme.palette.text.secondary,
        },
        sizeSmall: {
          padding: '8px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 700,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        edgeEnd: {
          padding: '12px 12px 12px 0',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        size: 'small',
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          paddingBottom: 0,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        rectangular: {
          borderRadius: theme.spacing(1),
        },
      },
    },
  };
};

export default componentThemeOverrides;
