import { ThemeVariant } from 'services/theming';
import freshmarkSwish from 'images/freshmark-hero-swish.svg';
import freshmarkCover from 'images/freshmark-hero.svg';
import freshmarkMapCover from 'images/freshmark-map-hero.svg';
import swish from 'images/hero-swish.svg';
import cover from 'images/hero.svg';
import inputsSwish from 'images/inputs-hero-swish.svg';
import inputsCover from 'images/inputs-hero.svg';
import inputsMapCover from 'images/inputs-map-hero.svg';
import mapCover from 'images/map-hero.svg';
import villaSwish from 'images/villa-hero-swish.svg';
import villaCover from 'images/villa-hero.svg';
import villaMapCover from 'images/villa-map-hero.svg';

const nileImages = {
  mountainHero: cover,
  mapHero: mapCover,
  swish: swish,
};
const inputsImages = {
  mountainHero: inputsCover,
  mapHero: inputsMapCover,
  swish: inputsSwish,
};
const freshmarkImages = {
  mountainHero: freshmarkCover,
  mapHero: freshmarkMapCover,
  swish: freshmarkSwish,
};
const villaImages = {
  mountainHero: villaCover,
  mapHero: villaMapCover,
  swish: villaSwish,
};

const themes = {
  [ThemeVariant.Villa]: villaImages,
  [ThemeVariant.FreshMark]: freshmarkImages,
  [ThemeVariant.Inputs]: inputsImages,
  [ThemeVariant.Nile]: nileImages,
  default: nileImages,
};

const getImagesByTheme = (theme: ThemeVariant) => {
  return themes[theme] || themes.default;
};

export default getImagesByTheme;
