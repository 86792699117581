import { useEffect, useState } from 'react';

/**
 * Sync a local state with a prop. Used when we action local changes to state, but also want to keep the state in sync with a remote source.
 * @param state State object to sync
 * @returns A tuple with the state and a setState function
 */
export default function useSyncedState<T>(current: T) {
  const [state, setState] = useState<T>(current);
  useEffect(() => {
    if (current) {
      setState(current);
    }
  }, [current]);
  return [state, setState] as const;
}
