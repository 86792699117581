import React, { useState, createContext, useContext, ReactNode } from 'react';

const GlobalContentContext = createContext<
  { showContent: (content: ReactNode) => void; hideContent: () => void } | undefined
>(undefined);

export const GlobalContentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<React.ReactNode | null>(null);

  const showContent = (content: ReactNode) => {
    setContent(content);
    setOpen(true);
  };

  const hideContent = () => {
    setOpen(false);
  };

  return (
    <GlobalContentContext.Provider value={{ showContent, hideContent }}>
      {children}
      {open && content}
    </GlobalContentContext.Provider>
  );
};

export const useGlobalContent = () => {
  const context = useContext(GlobalContentContext);
  if (!context) {
    throw new Error('useGlobalContent must be used within a GlobalContentProvider');
  }
  return context;
};
