import { Skeleton } from '@mui/material';
import { useState } from 'react';
import { useFeatures } from 'hooks/useFeatures';
import { useGlobalContent } from 'hooks/useGlobalContent';
import useHandleCustomSchemeAndQuery from 'hooks/useHandleCustomSchemeAndQuery';
import { Card as CardData, CardIntent } from 'generated/graphql';
import { ButtonCard } from './ButtonCard';
import ErrorReportingDialog from './ErrorReporting';
import { FormDialogState } from './FormDialog';
import IconCard from './IconCard';
import LazyFormDialog from './LazyFormDialog';
import { MenuItemCard } from './MenuItemCard';
import { defaultFormDialogState } from './default-form-dialog-state';

const CustomCard = ({
  data,
  isLoading,
  onRefresh = () => {},
  onClose = () => {},
}: {
  data: CardData;
  isLoading: boolean;
  onRefresh?: () => void;
  onClose?: () => void;
}) => {
  const intent = data.context;
  const [formDialog, setFormDialog] = useState<FormDialogState>(defaultFormDialogState);

  const { handleSchemeOrQuery } = useHandleCustomSchemeAndQuery(onRefresh);
  const { showContent } = useGlobalContent();
  const { isEnabled } = useFeatures();
  const isErrorReportingEnabled = isEnabled('ErrorReporting');

  const handleClick = () => {
    if (data.button.actions[0].code === 'report_error' && isErrorReportingEnabled) {
      showContent(<ErrorReportingDialog />);
      onClose();
      return;
    }
    const url = data.button.actions[0]?.url ?? '';
    const query = data.button.actions[0]?.query ?? '';
    handleSchemeOrQuery(url, query, { setFormDialog: setFormDialog });
  };

  return (
    <>
      {isLoading ? (
        <Skeleton height={100} variant="rectangular" sx={(theme) => ({ margin: theme.spacing(1) })} />
      ) : intent === CardIntent.IconCard ? (
        <IconCard data={data} onClick={handleClick} />
      ) : intent === CardIntent.Button ? (
        <ButtonCard data={data} onClick={handleClick} />
      ) : (
        <MenuItemCard data={data} onClick={handleClick} />
      )}
      <LazyFormDialog
        type={formDialog.type}
        editIDs={formDialog.editIDs}
        open={formDialog.open}
        onClose={() => {
          setFormDialog(defaultFormDialogState);
        }}
        onSubmit={() => {
          setFormDialog(defaultFormDialogState);
          if (onRefresh) onRefresh();
        }}
      />
    </>
  );
};

export default CustomCard;
