import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { getPrefixedRoute } from 'services/theming';

const useHandleLink = () => {
  const navigate = useNavigate();

  const handleLink = useCallback(
    (url: string) => {
      const target = getPrefixedRoute(url);
      if (target.startsWith('http')) {
        window.location.href = target;
      } else {
        navigate(target, { replace: true });
      }
    },
    [navigate],
  );

  return handleLink;
};

export default useHandleLink;
