import { LoadingButton } from '@mui/lab';
import { Box, SxProps } from '@mui/material';
import { getPrefixedRoute } from 'services/theming';
import { BaseTheme } from 'config/base-theme';
import { Button as GraphButton } from 'generated/graphql';
import BtnIcon from './BtnIcon';
import Button from './Button';

const ServerButton = ({
  button,
  loading = false,
  onClick,
  sx = {},
  buttonSx = {},
}: {
  button: GraphButton;
  loading?: boolean;
  onClick?: () => void;
  sx?: SxProps<BaseTheme>;
  buttonSx?: SxProps<BaseTheme>;
}) => {
  return (
    <Box sx={[{ textAlign: 'center' }, ...(Array.isArray(sx) ? sx : [sx])]}>
      {button.actions.length === 1 && button.actions[0].url.length > 0 ? (
        <Button
          size="medium"
          variant={button.variant.toLowerCase() as 'text' | 'outlined' | 'contained'}
          color={button.color.toLowerCase() as 'primary' | 'secondary'}
          startIcon={<BtnIcon icon={button.icon} />}
          sx={[
            (theme) => ({
              maxWidth: 250,
              margin: theme.spacing(1, 'auto', 0),
            }),
            ...(Array.isArray(buttonSx) ? buttonSx : [buttonSx]),
          ]}
          href={getPrefixedRoute(button.actions[0].url)}
        >
          {button.value}
        </Button>
      ) : (
        <LoadingButton
          size="medium"
          variant={button.variant.toLowerCase() as 'text' | 'outlined' | 'contained'}
          color={button.color.toLowerCase() as 'primary' | 'secondary'}
          startIcon={<BtnIcon icon={button.icon} />}
          sx={[
            (theme) => ({
              maxWidth: 250,
              margin: theme.spacing(1, 'auto', 0),
            }),
            ...(Array.isArray(buttonSx) ? buttonSx : [buttonSx]),
          ]}
          disabled={loading}
          loading={loading}
          onClick={onClick}
        >
          {button.value}
        </LoadingButton>
      )}
    </Box>
  );
};

export default ServerButton;
