import { useEffect } from 'react';
import { useLocation } from 'react-router';
import {
  FRESHMARK_ADMIN_PROFILE_ID,
  NILE_ADMIN_PROFILE_ID,
  VILLA_ADMIN_PROFILE_ID,
  INPUTS_ADMIN_PROFILE_ID,
  getThemeVariant,
  themeVariantToAdminProfileIdMap,
} from 'services/theming';

export interface SiteMeta {
  // TODO(tiaan): Add SVG icon support (https://evilmartians.com/chronicles/how-to-favicon-in-2021-six-files-that-fit-most-needs)
  title: string;
  manifest?: string;
  description?: string;
  shortcutIcon?: string;
  icon?: string;
  appleTouchIcon?: string;
}

const updateOrCreateLink = (rel: string, href: string, type?: string) => {
  const link: HTMLLinkElement = document.querySelector(`link[rel="${rel}"]`) || document.createElement('link');
  if (type) {
    link.type = type;
  }
  link.rel = rel;
  link.href = href;
  document.getElementsByTagName('head')[0].appendChild(link);
};

const adminIdToThemeMap: { [key: number]: SiteMeta } = {
  [NILE_ADMIN_PROFILE_ID]: {
    title: 'Nile Marketplace',
    manifest: '/nile-manifest.json',
    shortcutIcon: '/nile-favicon.ico',
    icon: '/nile-favicon-32x32.png',
  },
  [FRESHMARK_ADMIN_PROFILE_ID]: {
    title: 'FreshMark',
    manifest: '/freshmark-manifest.json',
    shortcutIcon: '/freshmark-favicon.ico',
    icon: '/freshmark-favicon-32x32.png',
  },
  [VILLA_ADMIN_PROFILE_ID]: {
    title: 'VillaCrop',
    manifest: '/villa-manifest.json',
    shortcutIcon: '/villa-favicon.ico',
  },
  [INPUTS_ADMIN_PROFILE_ID]: {
    title: 'Inputs',
    manifest: '/inputs-manifest.json',
    // TODO(tiaan): Create branded icons for Inputs
    shortcutIcon: '/nile-favicon.ico',
    icon: '/nile-favicon-32x32.png',
  },
};

export const useSiteMeta = () => {
  const location = useLocation();
  useEffect(() => {
    const adminProfileId = themeVariantToAdminProfileIdMap[getThemeVariant(location)];
    const siteMeta = adminIdToThemeMap[adminProfileId as number];
    document.title = siteMeta.title;

    if (siteMeta.manifest) {
      updateOrCreateLink('manifest', siteMeta.manifest, 'application/manifest+json');
    }
    if (siteMeta.shortcutIcon) {
      updateOrCreateLink('shortcut icon', siteMeta.shortcutIcon);
    }
    if (siteMeta.icon) {
      updateOrCreateLink('icon', siteMeta.icon, 'image/png');
    }
    if (siteMeta.appleTouchIcon) {
      updateOrCreateLink('apple-touch-icon', siteMeta.appleTouchIcon);
    }
  }, [location]);
};
