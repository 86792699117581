import { getThemeVariant, INPUTS_ADMIN_PROFILE_ID, ThemeVariant } from 'services/theming';
import useUser from './useUser';

const useIsInputsProfile = () => {
  const { user } = useUser();
  const themeVariant = getThemeVariant();
  const isInputsProfile = user?.adminProfileId ? user.adminProfileId === INPUTS_ADMIN_PROFILE_ID : false;
  return themeVariant === ThemeVariant.Inputs && isInputsProfile;
};

export default useIsInputsProfile;
