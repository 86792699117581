import { Button as MuiButton, ButtonProps } from '@mui/material';
import withClickTracking from 'hocs/withClickTracking';

interface Props extends Omit<ButtonProps, 'color'> {
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
}

const Button = withClickTracking(({ color = 'primary', ...rest }: Props) => {
  const validColors = ['inherit', 'primary', 'secondary', 'success', 'error', 'info', 'warning'];
  const buttonColor = validColors.includes(color) ? color : 'primary';
  return <MuiButton color={buttonColor} {...rest} />;
});

export default Button;
