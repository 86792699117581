import { TextField, InputAdornment, IconButton, SxProps } from '@mui/material';
import { useState } from 'react';
import { MdVisibilityOff, MdVisibility } from 'react-icons/md';
import { BaseTheme } from 'config/base-theme';
import { mergeSx } from 'utils/merge-sx';
import { Field } from 'generated/graphql';

const PasswordTextfield = ({
  field,
  isDisabled,
  sx,
  onChange,
}: {
  field: Field;
  isDisabled: boolean;
  sx?: SxProps<BaseTheme>;
  onChange(value: string, values?: string[]): void;
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      fullWidth
      name="password"
      value={field.value}
      onChange={(event) => onChange(event.target.value || '')}
      label="Password"
      disabled={isDisabled}
      type={showPassword ? 'text' : 'password'}
      variant="outlined"
      sx={mergeSx(
        (theme) => ({
          marginTop: theme.spacing(1),
        }),
        sx ?? {},
      )}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                color="primary"
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((current) => !current)}
              >
                {showPassword ? <MdVisibilityOff size={20} /> : <MdVisibility size={20} />}
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
};

export default PasswordTextfield;
