import { TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent, useState } from 'react';

interface NumberFieldProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
}

const NumberField = ({ value, onChange, ...rest }: NumberFieldProps) => {
  const [innerValue, setInnerValue] = useState<string>(value);
  return (
    <TextField
      variant="outlined"
      type="number"
      inputProps={{
        step: 1,
      }}
      {...rest}
      value={innerValue}
      onFocus={(event) => {
        if (rest.onFocus) rest.onFocus(event);
      }}
      onBlur={(event) => {
        const newInner = innerValue.replace(',', '.');
        setInnerValue(newInner === '' ? '0' : newInner);
        onChange(event as ChangeEvent<HTMLInputElement>, newInner);
        if (rest.onBlur) rest.onBlur(event);
      }}
      onChange={(event) => {
        const { value } = event.target;
        setInnerValue(value);
        onChange(event as ChangeEvent<HTMLInputElement>, value);
      }}
      onWheel={(event) => event.target instanceof HTMLElement && event.target.blur()}
    />
  );
};

export default NumberField;
