import { useEffect, useRef } from 'react';

// Based on: https://stackoverflow.com/questions/56283920/how-to-debounce-a-callback-in-functional-component-using-hooks
const useDebouncedFunc = <A extends any[]>(func: (...args: A) => void, wait: number = 500) => {
  const argsRef = useRef<A>();
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const cleanup = () => {
    if (timeout.current) clearTimeout(timeout.current);
  };
  // make sure our timeout gets cleared if
  // our consuming component gets unmounted
  useEffect(() => cleanup, []);
  return (...args: A) => {
    // capture latest args
    argsRef.current = args;
    // clear debounce timer
    cleanup();
    // start waiting again
    timeout.current = setTimeout(() => {
      if (argsRef.current) func(...argsRef.current);
    }, wait);
  };
};

export default useDebouncedFunc;
