import { useCallback, useState } from 'react';
import { isCompressionEnabled } from 'config/features';

const getSizeQuality = (size: number) => {
  size = size / 1024;
  if (size < 500) {
    // value measured in kb
    return 0.7;
  } else if (size < 1000) {
    return 0.6;
  } else {
    return 0.5;
  }
};

const useFileCompression = () => {
  const [compressedFile, setCompressedFile] = useState<File | null>(null);

  const compressFile = useCallback(async (file: File | null) => {
    try {
      if (file?.type?.startsWith('image/') && isCompressionEnabled) {
        if (file.size <= 100 * 1024) {
          setCompressedFile(file);
          return;
        }

        const image = new Image();
        const reader = new FileReader();

        reader.onload = (e) => {
          if (!e.target) {
            return;
          }
          image.src = e.target.result as string;

          image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const scaleFactor = getSizeQuality(file.size);

            canvas.width = image.width * scaleFactor;
            canvas.height = image.height * scaleFactor;

            ctx?.drawImage(image, 0, 0, canvas.width, canvas.height);

            canvas.toBlob(
              (blob) => {
                if (!blob) {
                  setCompressedFile(null);
                  return;
                }

                const compressedImage = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });
                setCompressedFile(compressedImage);
              },
              file.type,
              0.9,
            );
          };
        };

        reader.readAsDataURL(file);
      } else {
        setCompressedFile(file);
      }
    } catch (error) {
      setCompressedFile(file);
    }
  }, []);

  return [compressedFile, compressFile] as const;
};

export default useFileCompression;
