const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const getMonthName = (index: number, letterCount: number = 0) =>
  monthNames[index].substring(0, letterCount || monthNames[index].length);

export const getDayName = (index: number, letterCount: number = 0) =>
  dayNames[index].substring(0, letterCount || dayNames[index].length);

/**
 * Converts a string in simplified extended ISO format (ISO 8601) to YYYY-MM-DD
 * */
export const parseDateString = (dateStr: string) => {
  const date = new Date(dateStr);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return [year, month.toString().padStart(2, '0'), day.toString().padStart(2, '0')].join('-');
};

/**
 * @description convert client input format YYYY-MM-DD to server format, for now put all dates
 * in UTC by default, when we respect timezones the values (like delivery dates)
 * will need a time component too not just a date component.
 * */
export const formatDateString = (dateString: string): string => {
  const val = Date.parse(`${dateString}T00:00:00.000+00:00`);
  if (Number.isNaN(val)) return '';
  return new Date(val).toISOString();
};
