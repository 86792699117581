import { Draft, produce } from 'immer';
import { useCallback, useEffect, useState } from 'react';
import { getValue, setValue } from 'services/idb-store';
import hash from 'stable-hash';

/**
 * This hook is intented to provide basic caching of remote state.
 * a remote value is provided alongside a storage key to persist the state item in indexedDB.
 * a deterministic hash of the storage key is used to store the value in indexedDB since in most cases input variables for the associated query will be used.
 * Since it is assumed that these remote state objects will be deeply nested, immer is used to perform the state updates.
 * If a new remote state value is recieved it will replace the local state and update the indexedDB value.
 */
export function useSyncedMutableOfflineState<T>(remoteValue: T, storageKey: any) {
  const key = hash(storageKey);
  const [localValue, setLocalValue] = useState<any>(remoteValue);
  const set = useCallback((updater: (draft: Draft<T>) => void) => setLocalValue(produce(updater)), []);

  const resetValue = () => {
    setLocalValue({});
    setValue(key, {});
  };
  useEffect(() => {
    if (remoteValue) {
      setLocalValue(remoteValue);
      setTimeout(() => setValue(key, JSON.stringify(remoteValue)), 0);
    }
  }, [remoteValue, key]);

  useEffect(() => {
    if (!remoteValue) {
      const getFromStorage = async () => {
        try {
          const value = await getValue(key);
          if (value) {
            setLocalValue(JSON.parse(value));
          }
        } catch (error) {}
      };
      getFromStorage();
    }
  }, [remoteValue, key]);

  return [localValue, set, resetValue] as const;
}
