import { Cell, Label, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { ValidSeries, ChartLight, ChartDefault, ChartTooltipType, ChartVariant } from './ChartFunctions';
import ChartTooltips from './ChartTooltips';
import CustomLegend from './CustomLegend';

const RADIAN = Math.PI / 180;

const renderPieChartLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="middle">
        <tspan x={x}>{`${Math.round(percent * 100)}%`}</tspan>
      </text>
    </>
  );
};

const PieChartType = ({
  subTitle,
  subText,
  validSeries,
  variant = ChartVariant.DEFAULT,
  width,
  height,
  hiddenSeries,
  onLegendSelect = () => {},
}: {
  subTitle: string;
  subText: string;
  validSeries: ValidSeries[];
  variant: ChartVariant;
  width: number;
  height: number;
  hiddenSeries: string[];
  onLegendSelect?: (e: any) => void;
}) => {
  const style = variant === ChartVariant.LIGHT ? ChartLight : ChartDefault;
  const series = validSeries.map((s, index) => ({
    name: s.name,
    ...s.data[0],
    color: style.colors[index % style.colors.length],
  }));
  const filteredSeries = series.filter((s) => !hiddenSeries.includes(s.xDisplay)) ?? [];

  return (
    <PieChart width={width} height={height}>
      <Pie
        dataKey="y"
        nameKey="xDisplay"
        data={filteredSeries}
        fill="#8884d8"
        cx={width / 2}
        cy={height / 2 - 25}
        label={renderPieChartLabel}
        labelLine={false}
        innerRadius={80}
      >
        {filteredSeries?.map((s, i) => <Cell key={s.xDisplay + i} fill={s.color} />)}
        {variant === ChartVariant.LIGHT && (
          <>
            <Label value={subTitle} position="center" fontWeight="500" fontSize={14} dy={-12} />
            <Label value={subText} position="center" fontSize={18} fill="#000" fontWeight="bold" dy={10} />
          </>
        )}
      </Pie>
      <Tooltip
        allowEscapeViewBox={{ x: false, y: true }}
        content={<ChartTooltips type={ChartTooltipType.Detailed} />}
      />
      {style.legend && (
        <Legend
          content={<CustomLegend hiddenSeries={hiddenSeries} />}
          payload={series.map((entry) => ({
            id: entry.xDisplay,
            value: entry.y,
            name: entry.xDisplay,
            color: entry.color,
            type: 'circle',
          }))}
          onClick={onLegendSelect}
        />
      )}
    </PieChart>
  );
};

export default PieChartType;
