import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Stack,
  Typography,
  IconButton,
  useTheme,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useGlobalContent } from 'hooks/useGlobalContent';
import { SnackbarVariant, useSnackbar } from 'hooks/useSnackBar';
import useUser from 'hooks/useUser';
import { CATEGORIES, Category, PRIORITIES, Priority, reportTracingInfo } from 'utils/traceid';

const ErrorReportingDialog = () => {
  const [open, setOpen] = useState(true);
  const [category, setCategory] = useState(Category.Profile);
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState(Priority.Normal);
  const { hideContent } = useGlobalContent();
  const theme = useTheme();
  const { user } = useUser();
  const { showSnackbar } = useSnackbar();

  const handleCategoryChange = (event: any) => {
    setCategory(event.target.value);
  };

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };

  const handlePriorityChange = (event: any) => {
    setPriority(event.target.value);
  };

  const handleSubmitReport = () => {
    reportTracingInfo(user, category, description, priority);
    showSnackbar({ message: 'Error reported successfully', color: 'success' }, SnackbarVariant.ALERT);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    hideContent();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={(theme) => ({
          '& .MuiDialog-paper': {
            p: 1,
            [theme.breakpoints.down('sm')]: { p: 0 },
          },
        })}
      >
        <DialogContent
          sx={(theme) => ({
            minWidth: '420px',
            padding: theme.spacing(1.5),
            [theme.breakpoints.down('sm')]: { minWidth: '100vw' },
          })}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" sx={{ color: theme.palette.info.main }}>
              Report an error
            </Typography>

            <IconButton onClick={handleClose}>
              <MdClose size={24} fill={theme.palette.grey[700]} />
            </IconButton>
          </Stack>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.grey[700],
              mt: theme.spacing(1.5),
              mb: theme.spacing(2),
              fontSize: '0.875rem',
              maxWidth: 'calc(100% - 24px)',
            }}
          >
            We apologize for the inconvenience you’ve encountered. Kindly provide as much detail as possible so we can
            better assist you in resolving the issue.
          </Typography>

          {/* Priority Select */}
          <FormControl
            sx={{
              background: theme.palette.background.paper,
              borderRadius: theme.spacing(1),
            }}
            variant="outlined"
            margin="dense"
            fullWidth
          >
            {priority.length > 0 && <InputLabel color="info">Priority</InputLabel>}
            <Select color="info" label="Priority" value={priority} onChange={handlePriorityChange}>
              <MenuItem disabled value="None">
                <Typography color="textDisabled" variant="inherit">
                  Select a priority
                </Typography>
              </MenuItem>
              {PRIORITIES.map((priority) => (
                <MenuItem value={priority}>{priority}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Category Select */}
          <FormControl
            sx={{
              background: theme.palette.background.paper,
              borderRadius: theme.spacing(1),
            }}
            variant="outlined"
            margin="dense"
            fullWidth
          >
            <InputLabel color="info">Category</InputLabel>
            <Select color="info" label="Category" value={category} onChange={handleCategoryChange}>
              <MenuItem disabled value="None">
                <Typography color="textDisabled" variant="inherit">
                  Select a category
                </Typography>
              </MenuItem>
              {CATEGORIES.map((category) => (
                <MenuItem value={category}>{category}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Description TextField */}
          <TextField
            color="info"
            sx={{
              background: theme.palette.background.paper,
              borderRadius: theme.spacing(1),
            }}
            variant="outlined"
            margin="dense"
            slotProps={{ inputLabel: { shrink: true } }}
            fullWidth
            label="Description"
            multiline
            rows={4}
            value={description}
            onChange={handleDescriptionChange}
            placeholder="Please describe the error you encountered"
          />
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Stack direction="row" justifyContent="space-between" gap={1} sx={{ width: '100%' }}>
            <Button variant="text" color="info" onClick={handleClose} sx={{ width: '100%' }}>
              Cancel
            </Button>
            <LoadingButton variant="contained" color="info" onClick={handleSubmitReport} sx={{ width: '100%' }}>
              Send report
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ErrorReportingDialog;
