import { get, set, createStore, clear } from 'idb-keyval';

// The custom store implementation is to mitgate an issue with a closing database connection (https://nile-ag.sentry.io/issues/4980068973/events/7bffb21730184863a6ff338c51604f16/?project=4504723878248448&referrer=previous-event)
// To get around this we need to recreate the store when the connection is closed as mentioned here: https://github.com/jakearchibald/idb-keyval/issues/165
// The store functions are intented to be used for caching data and thus have no error handling as the data is not critical and the application will continue to work without it.

const createCustomStore = () => createStore('keyval-store', 'keyval');
let customStore = createCustomStore();

export const setValue = (key: string, value: any) => set(key, value, customStore).catch(console.error);
export const clearStore = () => clear(customStore).catch(console.error);
export const getValue = (key: string) =>
  get(key, customStore).catch((error) => {
    console.error(error);
    if (error instanceof Error && error?.message?.includes('connection is closing')) {
      customStore = createCustomStore();
      return get(key, customStore);
    }
  });
