import { Box } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import useUser from 'hooks/useUser';
import { hasRobotToken } from 'utils/hasRobotToken';
import Navigation from './Navigation';
import NetworkError from './NetworkError';

const Screen = ({ children = null }: { children?: ReactNode | null }) => {
  const user = useUser();
  if (hasRobotToken()) {
    return (
      <>
        {user ? (
          <>
            <Outlet />
            {children}
          </>
        ) : null}
      </>
    );
  }

  return (
    <Navigation>
      <ErrorBoundary
        fallback={({ error, componentStack }) => {
          // Type narrowing for the error object
          const typedError = error instanceof Error ? error : new Error('Unknown error');

          return (
            <NetworkError
              message="We have encountered an unexpected error."
              error={typedError}
              componentStack={componentStack}
            />
          );
        }}
      >
        <Box
          sx={{
            marginBottom: 'calc(env(safe-area-inset-bottom))',
            overflowX: 'clip',
          }}
        >
          <Outlet />
          {children}
        </Box>
      </ErrorBoundary>
    </Navigation>
  );
};

export default Screen;
