import { getThemeVariant, VILLA_ADMIN_PROFILE_ID, ThemeVariant } from 'services/theming';
import useUser from './useUser';

const useIsVillaProfile = () => {
  const { user } = useUser();
  const themeVariant = getThemeVariant();
  const isVillaProfile = user?.adminProfileId === VILLA_ADMIN_PROFILE_ID;
  return themeVariant === ThemeVariant.Villa || isVillaProfile;
};

export default useIsVillaProfile;
