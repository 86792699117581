const useImageThumbnail = (url?: string, thumbnail = '64', bucketOverride?: string) => {
  const isStorageUrl = bucketOverride
    ? bucketOverride === import.meta.env.REACT_APP_FIREBASE_QC_STORAGE_BUCKET
      ? url?.includes(bucketOverride.substring(5))
      : url?.includes(bucketOverride)
    : url?.includes(import.meta.env.REACT_APP_FIREBASE_STORAGE_BUCKET);
  return isStorageUrl && thumbnail
    ? url?.includes('?')
      ? url?.replace('?', `@thumb${thumbnail}?`)
      : `${url}@thumb${thumbnail}`
    : url;
};

export default useImageThumbnail;
