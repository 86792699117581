import { Table, TableBody, TableCell, TableHead, TableRow, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Fragment } from 'react';
import { BaseTheme } from 'config/base-theme';
import useIsViewport from 'hooks/useIsViewport';
import {
  FontStyle,
  FontType,
  TableCellType,
  TableColumn,
  Shipment,
  TableCell as TableCellData,
} from 'generated/graphql';
import ExpandableTableRows from '../ExpandableTableNew/SubTableRows';
import SubTableActionCard from '../SubTableActionCard/SubTableActionCard';
import useSeeShipment from './useSeeShipment';

const boxStyle = (theme: BaseTheme) => ({
  borderCollapse: 'separate',
  background: theme.palette.grey[50],
  borderRadius: theme.spacing(1),
});

const cellStyle = (theme: BaseTheme, cellData: TableCellData) => ({
  ...(cellData.fontStyle === FontStyle.Bold && {
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(0.8, 0),
  }),
  ...(cellData.fontType === FontType.Normal && { fontFamily: theme.typography.fontFamily }),
  ...(cellData.type === TableCellType.Icon && { width: theme.spacing(5) }),
  [theme.breakpoints.down('md')]: {
    width: 72,
    padding: theme.spacing(0.5),
  },
});

const ShipmentRowDetail = ({
  data,
  eTag,
  refresh,
  isFetching,
  onHide,
  onDisable,
  isAllDisabled = false,
  reset = false,
  onReset = () => {},
}: {
  data: Shipment;
  eTag: string;
  refresh(): void;
  isFetching?: boolean;
  isAllDisabled?: boolean;
  reset?: boolean;
  onReset?: (reset: boolean) => void;
  onHide?: (hide: boolean) => void;
  onDisable?: (disable: boolean) => void;
}) => {
  const isMobile = useIsViewport();
  useSeeShipment(data.id);

  return (
    <Grid container spacing={isMobile ? 6 : 2}>
      <Grid size={{ xs: 12, md: 7 }}>
        <Table
          size="small"
          sx={(theme) => ({
            ...boxStyle(theme),
            padding: theme.spacing(0.5),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.2, 0, 2),
              background: 'transparent',
            },
          })}
        >
          <TableHead>
            <TableRow>
              {data.columns.map((column: TableColumn, i) => (
                <TableCell
                  key={`${column.value + i}`}
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    padding: 0.25,
                  })}
                  align={column.align.toLowerCase() as 'left' | 'right'}
                >
                  {column.value}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.rows?.map((row, i) => (
              <TableRow key={i}>
                {row.map((cellData, j) => {
                  let cell;
                  if (cellData.type === TableCellType.Icon) {
                    cell = (
                      <TableCell
                        key={`${cellData.value + j}`}
                        sx={(theme) => cellStyle(theme, cellData)}
                        align={cellData.align.toLowerCase() as 'left' | 'right'}
                      >
                        {cellData.value.length > 0 && (
                          <Box
                            component="img"
                            src={cellData.value}
                            alt=""
                            sx={(theme) => ({
                              verticalAlign: 'middle',
                              width: theme.spacing(5),
                              height: theme.spacing(5),
                              borderRadius: theme.spacing(1),
                            })}
                          />
                        )}
                      </TableCell>
                    );
                  } else {
                    cell = (
                      <TableCell
                        key={`${cellData.value + j}`}
                        sx={(theme) => cellStyle(theme, cellData)}
                        align={cellData.align.toLowerCase() as 'left' | 'right'}
                      >
                        {cellData.value}
                      </TableCell>
                    );
                  }
                  return <Fragment key={j}>{cell}</Fragment>;
                })}
              </TableRow>
            ))}
            <ExpandableTableRows rows={data.expandableRows} />
          </TableBody>
        </Table>
      </Grid>
      <Grid size={{ xs: 12, md: 5 }}>
        <SubTableActionCard
          buttons={data.buttons}
          items={data.items}
          identifier={data.id}
          eTag={eTag}
          reset={reset}
          isDisabled={isAllDisabled ?? false}
          isFetching={isFetching ?? false}
          refresh={refresh}
          onReset={() => onReset(false)}
          onHide={onHide}
          onDisable={onDisable}
        />
      </Grid>
    </Grid>
  );
};

export default ShipmentRowDetail;
