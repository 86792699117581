import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ImageSliderDialog, { ImagesDialogState, defaultImagesDialogState } from 'components/ImageSliderDialog';
import { QualityChipVariant, QualityChips } from 'components/QualityChip';
import useImageThumbnail from 'hooks/useImageThumbnail';
import { ExpandableTableRow, Report, ReportInfo } from 'generated/graphql';

const QualityReportImage = ({
  entry,
  src,
  index,
  printerFriendly,
  setImagesDialog,
}: {
  entry: ReportInfo;
  src: string;
  index: number;
  printerFriendly?: string;
  setImagesDialog: (imagesDialog: ImagesDialogState) => void;
}) => {
  const isThirdImg = index === 2;
  const thumbnailSRC = useImageThumbnail(src, '128', import.meta.env.REACT_APP_FIREBASE_QC_STORAGE_BUCKET);

  if (!src || src === '') return null;
  return (
    <Stack
      direction={'row'}
      sx={{ cursor: 'pointer' }}
      key={index}
      onClick={() => {
        setImagesDialog({ open: true, images: entry.value.images, title: 'Images' });
      }}
    >
      <Box
        component="img"
        src={thumbnailSRC}
        loading="eager"
        sx={(theme) => ({
          width: theme.spacing(8),
          height: theme.spacing(8),
          objectFit: 'cover',
          borderRadius: theme.spacing(1),
          opacity: isThirdImg && !printerFriendly ? 0.3 : 1,
        })}
      />
      {isThirdImg && !printerFriendly && (
        <Box
          sx={(theme) => ({
            width: theme.spacing(8),
            height: theme.spacing(8),
            borderRadius: theme.spacing(1),
            marginLeft: theme.spacing(-9), // Allows overlap for
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <Typography
            variant="h5"
            sx={(theme) => ({
              fontWeight: theme.typography.fontWeightRegular,
            })}
          >
            + {entry.value.images.length - 2}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

const ReportTableRowDetail = ({ data }: { data: ExpandableTableRow }) => {
  const { printerFriendly } = useParams<{
    printerFriendly: string;
  }>();
  const [imagesDialog, setImagesDialog] = useState<ImagesDialogState>(defaultImagesDialogState);

  if (!data) return null;
  const entries = (data as unknown as Report).entries;

  const handleDialogClose = () => {
    setImagesDialog(defaultImagesDialogState);
  };

  return (
    <>
      <Stack
        gap={2}
        sx={(theme) => ({
          fontWeight: theme.typography.fontWeightMedium,
          padding: theme.spacing(2),
          backgroundColor: theme.palette.common.white,
          flexDirection: 'column',
          minWidth: '100%',
          [theme.breakpoints.up('sm')]: {
            height: 300,
            flexWrap: 'wrap',
          },
        })}
      >
        {entries.map((entry, index) => (
          <Box
            key={index}
            sx={{
              width: '50%',
            }}
          >
            <Typography
              align={entry.heading.align.toLowerCase() as 'left' | 'right'}
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightMedium,
                paddingY: theme.spacing(0.5),
              })}
            >
              {entry.heading.value}{' '}
            </Typography>
            {entry.value.value.length > 0 ? (
              <Typography
                variant={'body1'}
                sx={{
                  overflowWrap: 'break-word',
                }}
              >
                {entry.value.value}
              </Typography>
            ) : entry.value.buttons.length > 0 ? (
              <QualityChips chips={entry.value.buttons} variant={QualityChipVariant.Simple} />
            ) : entry.value.images.length > 0 ? (
              <Stack direction={'row'} gap={1}>
                {entry.value.images.slice(0, printerFriendly ? 5 : 3).map((img, imgIndex) => {
                  return (
                    <QualityReportImage
                      key={`${img}-${index}`}
                      entry={entry}
                      src={img}
                      index={imgIndex}
                      printerFriendly={printerFriendly}
                      setImagesDialog={setImagesDialog}
                    />
                  );
                })}
              </Stack>
            ) : null}
          </Box>
        ))}
      </Stack>
      <ImageSliderDialog
        open={imagesDialog.open}
        title={imagesDialog.title}
        images={imagesDialog.images}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default ReportTableRowDetail;
