import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { ReactNode, Suspense, useState } from 'react';
import { MdFilterList } from 'react-icons/md';
import Chart from 'components/Charts/Chart';
import { ChartVariant } from 'components/Charts/ChartFunctions';
import useIsViewport from 'hooks/useIsViewport';
import { ChartData } from 'generated/graphql';

const ChartContainer = ({
  title,
  data,
  quickViewFilter,
  isStacked = false,
}: {
  title: string;
  data: ChartData;
  quickViewFilter: ReactNode;
  isStacked?: boolean;
}) => {
  const isMobile = useIsViewport('sm');
  const [viewQuickFilter, setViewQuickFilter] = useState(false);
  return (
    <Box
      id="chartContainer"
      sx={(theme) => ({
        padding: isMobile ? 1 : 2,
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: theme.spacing(1),
      })}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          sx={(theme) => ({
            color: theme.palette.grey[800],
            fontSize: theme.spacing(2),
            fontWeight: theme.typography.fontWeightMedium,
          })}
        >
          {title}
        </Typography>
        {!isMobile && <>{quickViewFilter}</>}
        {isMobile && (
          <IconButton
            color="primary"
            sx={(theme) => ({ background: theme.palette.primary.light + '40' })}
            onClick={() => setViewQuickFilter(!viewQuickFilter)}
          >
            <MdFilterList size={20} />
          </IconButton>
        )}
      </Stack>

      <Stack direction={'row'} justifyContent="space-between" sx={{ marginTop: 1 }}>
        <Typography
          sx={(theme) => ({
            fontSize: '0.875rem',
            color: theme.palette.grey[600],
          })}
        >
          {data.chipLabel}
        </Typography>
      </Stack>
      {isMobile && (
        <Collapse in={viewQuickFilter} collapsedSize={0} sx={{ marginBottom: 2 }}>
          {quickViewFilter}
        </Collapse>
      )}
      <Suspense fallback={null}>
        <Box id="chartSection">
          <Chart data={data} variant={ChartVariant.LIGHT} isStacked={isStacked} />
        </Box>
      </Suspense>
    </Box>
  );
};

export default ChartContainer;
