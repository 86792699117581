import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import Button from './Button';
import CustomDateRangePicker from './CustomDateRangePicker';

export interface DateRangePickerProps {
  open: boolean;
  startDate: Dayjs;
  endDate: Dayjs;
  onDatesChange: (startDate: Dayjs, endDate: Dayjs) => void;
  onClose: () => void;
}

const DateRangePickerDialog = ({ open = false, startDate, endDate, onDatesChange, onClose }: DateRangePickerProps) => {
  const [dateRange, setDateRange] = useState({
    startDate: startDate,
    endDate: endDate,
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Choose date range</DialogTitle>
      <DialogContent>
        <DialogContentText>Please select the date range you would like to view.</DialogContentText>
        <CustomDateRangePicker
          debounceTimeout={0}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onStartDateChange={(date) => {
            setDateRange({ ...dateRange, startDate: date });
          }}
          onEndDateChange={(date) => {
            setDateRange({ ...dateRange, endDate: date });
          }}
        />
      </DialogContent>
      <DialogActions sx={{ paddingX: 3, paddingBottom: 3 }}>
        <Button variant="outlined" fullWidth onClick={onClose}>
          Close
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            onDatesChange(dateRange.startDate, dateRange.endDate);
            onClose();
          }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DateRangePickerDialog;
