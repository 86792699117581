import { Button, Card, Stack, Typography } from '@mui/material';
import { Card as CardData } from 'generated/graphql';
import BtnIcon from './BtnIcon';

const IconCard = ({ data, onClick }: { data: CardData; onClick: () => void }) => {
  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: data.color,
        gap: 1,
        marginY: theme.spacing(1),
      })}
    >
      <BtnIcon icon={data.icon} />
      <Stack>
        <Typography
          variant="body1"
          sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, paddingX: theme.spacing(1) })}
        >
          {data.title}
        </Typography>
        <Typography
          variant="body1"
          sx={(theme) => ({
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.grey[800],
            paddingX: theme.spacing(1),
          })}
        >
          {data.description}
        </Typography>
        <Button
          variant={(data.button.variant?.toLowerCase() as any) || 'contained'}
          color={(data.button.color?.toLowerCase() as any) || 'primary'}
          sx={(theme) => ({
            padding: theme.spacing(0.1, 1),
            minWidth: 'min-content',
            whiteSpace: 'nowrap',
            alignSelf: 'start',
          })}
          onClick={(event) => {
            event.stopPropagation();
            onClick();
          }}
        >
          {data.button.value}
        </Button>
      </Stack>
    </Card>
  );
};

export default IconCard;
