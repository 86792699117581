import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from 'react-router-dom';
import { logAnalytics } from 'utils/logAnalytics';

const useRouteAnalytics = () => {
  const location = useLocation();
  useEffect(() => {
    const pagePath = location.pathname + location.search;
    ReactPixel.pageView();
    logAnalytics('screen_view', { page_path: pagePath });
    logAnalytics('page_view', { page_path: pagePath });
  }, [location]);
  return null;
};

export default useRouteAnalytics;
