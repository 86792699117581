import { useMediaQuery, useTheme } from '@mui/material';

/**
 * Boolean hook to check if the viewport is less than or equal to the specified breakpoint.
 * @param viewport The breakpoint to check against. Defaults to 'md'.
 * @returns A boolean indicating if the viewport is less than or equal to the specified breakpoint.
 */
const useIsViewport = (viewport: 'sm' | 'md' | 'lg' = 'md') => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(viewport));
};

export default useIsViewport;
