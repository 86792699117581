import { User } from 'services/firebase';
import { getUserFromParsedIdToken } from 'hooks/useUser';

const refreshFirebaseUser = async ({
  user,
  setUser,
  forceRefresh = false,
}: {
  user: User | null;
  setUser?: any;
  forceRefresh?: boolean;
}): Promise<string> => {
  const authUser = await user?.getIdTokenResult(forceRefresh);

  if (!authUser) {
    throw new Error('Failed to get user please try log in again');
  }
  if (!authUser.token) {
    throw new Error('no token');
  }

  if (setUser) {
    setUser(getUserFromParsedIdToken(authUser));
  }
  return authUser.token;
};

export default refreshFirebaseUser;
