import lazy from '@fatso83/retry-dynamic-import/react-lazy';
import { Suspense } from 'react';
import { FormDialogProps } from './FormDialog';

const FormDialog = lazy(() => import('./FormDialog'));

const LazyFormDialog = (props: FormDialogProps) => (
  <Suspense fallback={null}>{props.open && <FormDialog {...props} />}</Suspense>
);

export default LazyFormDialog;
