import { LinearProgress, LinearProgressProps } from '@mui/material';

const BorderLinearProgress = ({ ...rest }: LinearProgressProps) => (
  <LinearProgress
    {...rest}
    sx={(theme) => ({
      height: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      '&.MuiLinearProgress-colorPrimary': {
        backgroundColor: theme.palette.grey[200],
      },
      '& .MuiLinearProgress-bar': {
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.secondary.main,
      },
    })}
  />
);

export default BorderLinearProgress;
