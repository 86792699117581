import { useTheme } from '@mui/material';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, Label, YAxis, Tooltip, Legend, Bar } from 'recharts';
import useIsViewport from 'hooks/useIsViewport';
import { ChartLayout } from 'generated/graphql';
import {
  CHART_DEBOUNCE_TIME,
  ValidSeries,
  ChartVariant,
  ChartDefault,
  ChartLight,
  ChartTooltipType,
} from './ChartFunctions';
import ChartTooltips, { ToolTipVariant } from './ChartTooltips';

const transformData = (validSeries: ValidSeries[]) => {
  return validSeries.map((series) =>
    series.data.reduce(
      (acc, point) => ({
        ...acc,
        [point.xDisplay]: point.y,
        ...point,
        xDisplay: series.data.length === 1 ? point.xDisplay : '',
      }),
      {
        name: series.name,
      },
    ),
  );
};

const excludeKeys = ['name', 'xDisplay', 'yDisplay', 'x', 'y', 'tooltipInfo', 'tooltipLabel', '__typename'];

const getUniqueSubCategories = (transformedData: Object[]) => {
  const uniqueSubCategories = new Set<string>();

  transformedData.forEach((category) => {
    Object.keys(category)
      .filter((key) => !excludeKeys.includes(key))
      .forEach((subCat) => uniqueSubCategories.add(subCat));
  });

  return Array.from(uniqueSubCategories); // Convert Set to Array
};

const getUniqueSubCategoriesColours = (uniqueSubCategories: string[], variant: ChartVariant) => {
  const style = variant === ChartVariant.LIGHT ? ChartLight : ChartDefault;
  const categoryColours = new Map();
  uniqueSubCategories.forEach((category, index) => {
    categoryColours.set(category, style.colors[index % style.colors.length]);
  });
  return categoryColours;
};

interface LayoutTypeAxisProps {
  dataKey?: string;
  type?: 'number' | 'category';
}

const LayoutTypeInfo: Record<ChartLayout, Record<'x' | 'y', LayoutTypeAxisProps>> = {
  [ChartLayout.Horisontal]: {
    x: {
      type: 'number',
    },
    y: {
      dataKey: 'name',
      type: 'category',
    },
  },
  [ChartLayout.Vertical]: {
    x: {
      dataKey: 'name',
      type: 'category',
    },
    y: {
      type: 'number',
    },
  },

  [ChartLayout.None]: {
    x: {},
    y: {},
  },
};

const CustomXTick = (props: any) => {
  const { x, y, payload } = props;

  if (typeof payload.value !== 'string') return null;

  const [label, subLabel] = payload.value.split(' ');

  return (
    <g>
      <text x={x} y={y + 10} textAnchor="middle" fontSize={12} fill="grey">
        {label}
      </text>
      <text x={x} y={y + 30} textAnchor="middle" fontSize={12} fill="grey">
        {subLabel}
      </text>
    </g>
  );
};

const BarChartType = ({
  validSeries,
  layout = ChartLayout.Horisontal,
  variant = ChartVariant.DEFAULT,
  isStacked = false,
  subText = '',
  axisLabelX = undefined,
  axisLabelY = undefined,
}: {
  validSeries: ValidSeries[];
  layout?: ChartLayout;
  variant: ChartVariant;
  axisLabelX?: string;
  axisLabelY?: string;
  isStacked?: boolean;
  subText?: string;
}) => {
  const transformedData = transformData(validSeries);
  const uniqueSubCategories = getUniqueSubCategories(transformedData);
  const uniqueSubCategoriesColours = getUniqueSubCategoriesColours(uniqueSubCategories, variant);

  const isMobile = useIsViewport();
  const barChartHeight = layout === ChartLayout.Horisontal ? validSeries.length * 50 + 100 : 450;
  const theme = useTheme();

  return (
    <ResponsiveContainer height={barChartHeight} width="99%" debounce={CHART_DEBOUNCE_TIME}>
      <BarChart
        width={500}
        height={barChartHeight}
        data={transformedData}
        margin={{
          top: 5,
          right: 30,
          left: isMobile ? 30 : 60,
          bottom: isMobile ? 20 : 60,
        }}
        layout={layout === ChartLayout.Horisontal ? 'vertical' : 'horizontal'}
        barSize={50}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={LayoutTypeInfo[layout].x.dataKey} type={LayoutTypeInfo[layout].x.type} tick={CustomXTick}>
          <Label value={subText} offset={-2} position="insideBottomRight" />
          <Label
            value={axisLabelX}
            offset={isMobile ? -10 : -40}
            position="insideBottom"
            style={{ fill: theme.palette.text.primary, fontWeight: theme.typography.fontWeightMedium }}
          />
        </XAxis>
        <YAxis
          dataKey={LayoutTypeInfo[layout].y.dataKey}
          type={LayoutTypeInfo[layout].y.type}
          tickFormatter={(value) => {
            if (LayoutTypeInfo[layout].y.type !== 'number') {
              return value;
            }
            if (value >= 1000000) {
              // Format numbers greater than or equal to 1 million (e.g., 1050000 -> R 10,5 m)
              return `R ${(value / 1000000).toFixed(1).replace('.', ',')}m`;
            } else if (value >= 1000) {
              // Format numbers between 1000 and 1 million (e.g., 700000 -> R 700k)
              return `R ${(value / 1000).toFixed(0)}k`;
            }
            // Return the number as is if it's less than 1000
            return `R ${value}`;
          }}
        >
          <Label
            value={axisLabelY}
            offset={isMobile ? -20 : -50}
            position="insideLeft"
            angle={-90}
            style={{
              textAnchor: 'middle',
              fill: theme.palette.text.primary,
              fontWeight: theme.typography.fontWeightMedium,
            }}
          />
        </YAxis>
        <Tooltip
          content={
            <ChartTooltips
              type={ChartTooltipType.Simple}
              chartVariant={variant}
              variant={ToolTipVariant.LIGHT}
              isStacked={isStacked}
            />
          }
        />
        {isStacked && <Legend />}

        {uniqueSubCategories.map((subCat, index) => {
          const subCatColour = uniqueSubCategoriesColours.get(subCat);
          return (
            <Bar
              key={index}
              dataKey={subCat}
              stackId="a"
              fill={isStacked ? subCatColour : layout === ChartLayout.Horisontal ? '#B3CE91' : '#054B54'}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartType;
