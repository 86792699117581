import { Box, Button, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { BaseTheme } from 'config/base-theme';
import useIsViewport from 'hooks/useIsViewport';
import { ChartDataPoint, InfoPair } from 'generated/graphql';
import { ChartDefault, ChartLight, ChartTooltipType, ChartVariant } from './ChartFunctions';

interface DetailedChartTooltipProps {
  color?: string;
  name?: string;
  id?: string;
  value?: string;
  item?: ChartDataPoint;
}

const DrawerTooltip = ({ payload, open, onSetState }: any) => {
  const isMobile = useIsViewport('md');
  const [prevPayload, setPrevPayload] = useState(payload);

  useEffect(() => {
    if (prevPayload[0] && payload[0] && prevPayload[0].payload.x !== payload[0].payload.x) {
      onSetState(true);
    }
    setPrevPayload(payload);
  }, [payload, prevPayload, onSetState]);

  return (
    <Drawer
      open={open}
      anchor="right"
      PaperProps={{
        sx: { width: isMobile ? '100vw' : '320px' },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={(theme) => ({
          paddingY: 1,
          paddingX: 0.5,
          backgroundColor: isMobile ? theme.palette.primary.main : 'inherit',
        })}
      >
        <Stack direction="row" alignItems="center" gap={0.25}>
          <IconButton
            size="small"
            sx={(theme) => ({
              paddingY: 1,
              color: isMobile ? theme.palette.primary.contrastText : theme.palette.grey[700],
            })}
            onClick={() => onSetState(false)}
          >
            <MdChevronLeft size={24} />
          </IconButton>
          <Typography
            sx={(theme) => ({
              color: isMobile ? theme.palette.primary.contrastText : theme.palette.grey[700],
              fontSize: '0.875rem',
            })}
          >
            Pricing data
          </Typography>
        </Stack>
      </Stack>
      <Stack
        gap={2}
        sx={(theme: BaseTheme) => ({
          minWidth: '250px',
          padding: theme.spacing(1.5),
          fontSize: '0.75rem',
          maxHeight: 'calc(100vh - 50px - 56px)', //bottom - top section
          overflowY: 'scroll',
        })}
      >
        {payload.map((current: any, index: number) => {
          const item: ChartDataPoint = current.payload;
          return (
            <DetailedChartTooltip key={index} name={current.name} color={current.color} id={current.id} item={item} />
          );
        })}
      </Stack>

      <Stack
        direction="row"
        sx={(theme) => ({
          position: 'absolute',
          bottom: '0',
          width: '100%',
          boxShadow: `0px 10px 10px ${theme.palette.grey[200]}, 0px -10px 10px ${theme.palette.grey[200]}`,
          paddingX: theme.spacing(2),
          paddingY: theme.spacing(1),
          background: theme.palette.common.white,
        })}
      >
        <Button onClick={() => onSetState(false)} variant="contained" sx={{ width: '100%' }}>
          Close
        </Button>
      </Stack>
    </Drawer>
  );
};

// TODO: Investigate. Rename to DrawerTooltipItems if not used anywhere else besides drawer @Amy
const DetailedChartTooltip = ({ color, name, id, item }: DetailedChartTooltipProps) => {
  return (
    <>
      {item?.tooltipInfo && (
        <Typography sx={(theme) => ({ marginTop: 0.5, fontWeight: theme.typography.fontWeightBold, color: color })}>
          {name}
        </Typography>
      )}
      {item?.tooltipInfo?.map((info: InfoPair, indexInfo) => (
        <Stack
          key={indexInfo}
          direction="row"
          justifyContent="space-between"
          sx={(theme) => ({
            fontWeight: info?.strong ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
            fontSize: '0.75rem',
          })}
        >
          <Typography sx={{ marginRight: 3 }}>{info?.label}</Typography>
          <Typography>{info?.value}</Typography>
        </Stack>
      ))}
      {!item?.tooltipInfo && (
        <Stack direction="row" gap={2}>
          <Typography
            key={id}
            variant="body1"
            sx={(theme: BaseTheme) => ({
              fontSize: '0.75rem',
              margin: theme.spacing(0, 0, 0.25),
            })}
          >
            {item?.tooltipLabel}
          </Typography>
          <Typography
            key={id}
            variant="body1"
            sx={(theme: BaseTheme) => ({ fontSize: '0.75rem', margin: theme.spacing(0, 0, 0.25) })}
          >
            {item?.yDisplay}
          </Typography>
        </Stack>
      )}
    </>
  );
};

const SimpleChartTooltip = ({ current, chartVariant, isStacked, index }: any) => {
  const style = chartVariant === ChartVariant.LIGHT ? ChartLight : ChartDefault;

  return (
    <Stack
      key={index}
      direction="row"
      justifyContent="space-between"
      sx={(theme) => ({
        padding: theme.spacing(1),
        backgroundColor: isStacked ? style.colors[index % style.colors.length] : undefined,
        gap: 3,
      })}
    >
      <Typography
        sx={(theme) => ({
          fontWeight: isStacked ? theme.typography.fontWeightRegular : theme.typography.fontWeightBold,
        })}
      >
        {current.payload.xDisplay ?? current.name}
      </Typography>
      <Typography
        sx={(theme) => ({
          fontWeight: isStacked ? theme.typography.fontWeightRegular : theme.typography.fontWeightBold,
        })}
      >
        {current.payload.yDisplay ?? current.value}
      </Typography>
    </Stack>
  );
};

export enum ToolTipVariant {
  DEFAULT = 'default',
  LIGHT = 'light',
}
const CustomChartTooltip = ({
  type,
  active,
  payload,
  label,
  chartVariant,
  isStacked,
  open,
  onSetState,
  variant = ToolTipVariant.DEFAULT,
}: any) => {
  if (active && payload && Array.isArray(payload) && payload[0]) {
    return type === ChartTooltipType.Drawer ? (
      <DrawerTooltip payload={payload} open={open} onSetState={onSetState} />
    ) : (
      <Box
        sx={(theme) => ({
          borderRadius: theme.spacing(0.5),
          backgroundColor: variant === ToolTipVariant.LIGHT ? theme.palette.common.white : theme.palette.primary.main,
          border: variant === ToolTipVariant.LIGHT ? `1px solid ${theme.palette.primary.main}` : 'none',
          color: variant === ToolTipVariant.LIGHT ? theme.palette.primary.main : theme.palette.common.white,
          padding: type === ChartTooltipType.Detailed ? theme.spacing(1.5) : theme.spacing(0.5),
          fontSize: '0.75rem',
          minWidth: type === ChartTooltipType.Detailed ? '200px' : '100px',
        })}
      >
        {type !== ChartTooltipType.Detailed && isStacked && (
          <Typography
            className="label"
            sx={(theme) => ({ marginBottom: theme.spacing(1), fontWeight: theme.typography.fontWeightBold })}
          >
            {label}
          </Typography>
        )}

        {payload.map((current: any, index: number) => {
          const item: ChartDataPoint = current.payload;
          return type === ChartTooltipType.Detailed ? (
            <DetailedChartTooltip key={index} name={current.name} color={current.color} id={current.id} item={item} />
          ) : (
            <SimpleChartTooltip
              key={index}
              current={current}
              index={index}
              chartVariant={chartVariant}
              isStacked={isStacked}
            />
          );
        })}
      </Box>
    );
  }
  return null;
};

export default CustomChartTooltip;
