import { LoadingButton } from '@mui/lab';
import { Divider, Stack } from '@mui/material';
import { OperationContext } from 'urql';
import Form from 'components/Form';
import useIsViewport from 'hooks/useIsViewport';
import { buildUpdatedForm } from 'utils/form';
import { Form as FormType, Button as ButtonType, EditShipmentIntent } from 'generated/graphql';

const EditShipmentInfo = ({
  form,
  actionButtons,
  disableActionButtons = false,
  hideActionButtons = false,
  submitLoading = false,
  setForm,
  onRefetch,
  onSubmit,
}: {
  form?: FormType;
  actionButtons?: ButtonType[];
  disableActionButtons?: boolean;
  hideActionButtons?: boolean;
  submitLoading?: boolean;
  setForm: (value: any) => void;
  onRefetch: (opts?: Partial<OperationContext> | undefined) => void;
  onSubmit: (customIntent?: EditShipmentIntent) => void;
}) => {
  const isMobile = useIsViewport('md');

  return (
    <>
      {!!form && (
        <Stack
          sx={(theme) => ({
            background: theme.palette.common.white,
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: theme.spacing(1),
            padding: theme.spacing(2),
            [theme.breakpoints.down('md')]: {
              background: theme.palette.background.default,
              border: 'none',
            },
            [theme.breakpoints.up('md')]: {
              boxShadow: `inset 0px 505px 0px -500px ${theme.palette.primary.main}, 0 5px 5px -5px #dadada`,
              marginTop: theme.spacing(0.25), // Makes it so that the form aligns with tableActions
            },
          })}
        >
          <Form
            hasWhiteFields
            hideTitle={!isMobile}
            form={form as FormType}
            onRefresh={() => {
              onRefetch({ requestPolicy: 'network-only' });
            }}
            onFieldUpdate={(field, value, values) =>
              setForm((oldForm: any) => oldForm && buildUpdatedForm(oldForm, field, value, values))
            }
          />
          {!hideActionButtons && (
            <Divider
              sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                  marginX: theme.spacing(-2),
                },
              })}
            />
          )}
          {!isMobile && !hideActionButtons && actionButtons && (
            <Stack
              sx={(theme) => ({
                marginTop: theme.spacing(2),
                display: 'flex',
                justifyContent: 'flex-end',
                gap: theme.spacing(1),
              })}
            >
              {actionButtons.map((button, index) => {
                if (!button) return null;
                return (
                  <LoadingButton
                    key={index}
                    color={(button.color?.toLowerCase() as any) || 'secondary'}
                    variant={(button.variant?.toLowerCase() as any) || 'contained'}
                    disabled={disableActionButtons}
                    loading={submitLoading}
                    onClick={() => onSubmit(button.actions?.[0]?.code as EditShipmentIntent | undefined)}
                  >
                    {button.value || 'Save'}
                  </LoadingButton>
                );
              })}
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
};

export default EditShipmentInfo;
