import { Box, Typography } from '@mui/material';
import { FaCalendarCheck } from 'react-icons/fa';
import { MdContentPasteOff, MdOutlineFolderOff } from 'react-icons/md';
import { baseTheme } from 'config/base-theme';

export enum EmptyTableVariant {
  Default = 'Default',
  Orders = 'Orders',
  Quality = 'Quality',
}

const emptyStateMap = {
  [EmptyTableVariant.Default]: {
    graphic: <MdOutlineFolderOff size={68} color={baseTheme.palette.grey[400]} />,
    title: 'No data available',
  },
  [EmptyTableVariant.Orders]: {
    graphic: <FaCalendarCheck size={68} color={baseTheme.palette.grey[400]} />,
    title: 'You have no new orders',
  },
  [EmptyTableVariant.Quality]: {
    graphic: <MdContentPasteOff size={68} color={baseTheme.palette.grey[400]} />,
    title: 'No new quality reports',
  },
};

export const EmptyTableState = ({ variant = EmptyTableVariant.Default }: { variant?: EmptyTableVariant }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        top: 100,
      }}
    >
      <Box
        sx={(theme) => ({
          margin: theme.spacing(1.5, 0),
        })}
      >
        {emptyStateMap[variant].graphic}
      </Box>
      <Typography
        sx={{
          color: 'grey.600',
          fontSize: '1rem',
          fontWeight: 500,
        }}
      >
        {emptyStateMap[variant].title}
      </Typography>
    </Box>
  );
};
