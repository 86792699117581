import { useCallback } from 'react';
import { useNavigate } from 'react-router';

/**
 * Returns two functions, one to get a url param, and one to update one.
 */
const useSearch = (): [
  (key: string) => string | undefined,
  (key: string, value: string) => void,
  (key: string) => void,
] => {
  const navigate = useNavigate();
  const get = useCallback((key: string) => new URLSearchParams(window.location.search).get(key)?.toString(), []);
  const set = useCallback(
    (key: string, value: string) => {
      const searchParams = new URLSearchParams(window.location.search);

      searchParams.set(key, value);
      navigate({
        pathname: window.location.pathname,
        search: '?' + searchParams.toString(),
      });
    },
    [navigate],
  );
  const remove = useCallback((key: string) => {
    const params = new URLSearchParams(window.location.search);
    params.delete(key);

    // Update the browser's address bar
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }, []);
  return [get, set, remove];
};

export default useSearch;
