import { MdChat } from 'react-icons/md';
import { Link as RouterLink } from 'react-router-dom';
import { getPrefixedRoute } from 'services/theming';
import IconButton from 'components/IconButton';
import { useWhiteLabelling } from './WhiteLabellingProvider';

export const HelpActions = () => {
  const { isFreshmarkProfile } = useWhiteLabelling();
  if (isFreshmarkProfile) {
    return (
      <IconButton as={RouterLink} to={getPrefixedRoute('/chat')} size="small" aria-label="Chat" color="inherit">
        <MdChat size={24} />
      </IconButton>
    );
  }
  return null;
};

export default HelpActions;
