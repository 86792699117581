import { createTheme, responsiveFontSizes } from '@mui/material';
import { baseTheme, BaseTheme } from './base-theme';
import componentThemeOverrides from './component-theme-overrides';

const theme: BaseTheme = createTheme({
  breakpoints: {
    ...baseTheme.breakpoints,
  },
  palette: {
    ...baseTheme.palette,
    primary: {
      dark: '#243B42',
      main: '#274149',
      light: '#71c8ea',
    },
    secondary: {
      main: '#E30613',
    },
    tertiary: {
      dark: '#B8B8B8',
      main: '#DADADA',
      light: '#F5F5F5',
    },
    info: {
      dark: '#127238',
      main: '#009b3d',
      light: '#2fc86b',
    },
  },
  typography: {
    ...baseTheme.typography,
  },
  shape: baseTheme.shape,
});

export const villaTheme = responsiveFontSizes(createTheme(theme, { components: componentThemeOverrides(theme) }));
