import { BaseTheme } from 'config/base-theme';
import { FontColor, FontStyle, FontType, TableCell as TableCellData, TableType } from 'generated/graphql';

export interface CellStyle {
  theme: BaseTheme;
  cellData: TableCellData;
  type: TableType;
  hideLastBorder?: boolean;
  isNested?: boolean;
  shiftRight?: boolean;
  hideLeftBorder?: boolean;
  hideRightBorder?: boolean;
  isInline?: boolean;
  index: number;
}

const getFontColor = (fontColor: FontColor) => {
  switch (fontColor) {
    case FontColor.Primary:
      return 'primary';
    case FontColor.Secondary:
      return 'secondary';
    case FontColor.Tertiary:
      return 'tertiary';
    case FontColor.Success:
      return 'success';
    case FontColor.Warning:
      return 'warning';
    case FontColor.Info:
      return 'info';
    default:
      return 'primary';
  }
};

const ExpandableCellStyle = (style: CellStyle) => {
  const { theme, cellData, type, hideLastBorder, isNested, shiftRight, hideLeftBorder, hideRightBorder, isInline } =
    style;

  return {
    ...(type === TableType.Statistics && {
      '&.MuiTableCell-root': {
        borderRight: hideRightBorder ? 'hidden' : '',
        borderLeft: hideLeftBorder ? 'hidden' : '',
        background: !isNested && theme.palette.tertiary.light,
        paddingLeft: shiftRight ? theme.spacing(2) : '',
        verticalAlign: 'bottom',
        paddingY: theme.spacing(1),
        textAlign: cellData.align,
        ...(style.index === 0 && {
          position: 'sticky',
          left: 0,
          zIndex: 1,
          boxShadow: 'inset -10px 0 1px -10px rgba(0,0,0,0.4)',
          borderBottom: 'none',
          borderRight: 'none',
        }),
        display: isInline ? 'flex' : '',
        gap: 1,
      },
    }),
    ...(type === TableType.Basic && {
      '&.MuiTableCell-root': {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        background: theme.palette.common.white,
        ...(style.index === 0 && {
          minWidth: '100px',
          position: 'sticky',
          left: 0,
          zIndex: 1,
          [theme.breakpoints.down('md')]: {
            borderRight: `1px solid ${theme.palette.grey[300]}`,
          },
        }),
      },
    }),
    ...(cellData.fontStyle === FontStyle.Bold && { fontWeight: theme.typography.fontWeightMedium }),
    ...(cellData.fontStyle === FontStyle.Badge && {
      padding: theme.spacing(0.4, 1),
      margin: theme.spacing(1),
      '&.MuiTableCell-root > span': {
        border: `1px solid ${theme.palette[getFontColor(cellData.fontColor)]?.main ?? theme.palette.primary.light}`,
        padding: theme.spacing(0.75, 1),
        borderRadius: theme.spacing(4),
        color: theme.palette[getFontColor(cellData.fontColor)]?.main ?? theme.palette.primary.light,
        fontFamily: theme.typography.fontFamily,
      },
    }),
    ...(cellData.fontStyle === FontStyle.Inactive && {
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.disabled,
    }),
    ...(cellData.fontStyle === FontStyle.Highlighted && {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.light,
    }),
    ...((cellData.fontType === FontType.Normal || cellData.fontStyle === FontStyle.Badge) && {
      fontFamily: theme.typography.fontFamily,
    }),
    ...(cellData.fontType === FontType.Mono && { fontFamily: 'Roboto Mono' }),
    ...(hideLastBorder && { border: 0 }),
    ...(type === TableType.Reporting && { borderBottom: `2px solid ${theme.palette.background.default}` }),
    ...(type !== TableType.Detail &&
      type !== TableType.Reporting && { borderBottom: `1px solid ${theme.palette.common.white}` }),
    ...(cellData.fontColor && {
      color: theme.palette[getFontColor(cellData.fontColor)]?.main,
    }),
  };
};

export default ExpandableCellStyle;
