import { useEffect, useState } from 'react';

export enum OnlineStatus {
  Online = 'online',
  Offline = 'offline',
  Unknown = 'unknown',
}

const useOnlineStatus = (): OnlineStatus => {
  const [status, setStatus] = useState<OnlineStatus>(OnlineStatus.Unknown);

  useEffect(() => {
    const handleOnline = () => setStatus(OnlineStatus.Online);
    const handleOffline = () => setStatus(OnlineStatus.Offline);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Initial check
    setStatus(window.navigator.onLine ? OnlineStatus.Online : OnlineStatus.Offline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return status;
};

export default useOnlineStatus;
