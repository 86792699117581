const STAGING_API_URL = 'https://gapi-rnotfbb3ta-ez.a.run.app';
const PROD_API_URL = 'https://gapi-2idh3vosoa-ez.a.run.app';

export const isProd = import.meta.env.REACT_APP_GAPI_URL?.includes(PROD_API_URL);
export const isStaging = import.meta.env.REACT_APP_GAPI_URL?.includes(STAGING_API_URL);
export const isLocal =
  window.location.host.includes('localhost') ||
  import.meta.env.REACT_APP_GAPI_URL?.includes('localhost') ||
  import.meta.env.REACT_APP_GAPI_URL?.includes('nile.local');
export const isTest = isStaging || isLocal;
