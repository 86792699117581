import { TableCell, TableCellProps } from '@mui/material';
import { mergeSx } from 'utils/merge-sx';

const ExpandableTableCell = ({ sx, ...rest }: TableCellProps) => (
  <TableCell
    {...rest}
    sx={mergeSx(
      (theme) => ({
        '&.MuiTableCell-root': {
          padding: theme.spacing(0.5, 1),
          hyphens: 'auto',
          minWidth: 60,
          [theme.breakpoints.down('sm')]: {
            width: 100,
            padding: theme.spacing(0.5, 0.5),
          },
        },
      }),
      sx ?? {},
    )}
  />
);

export default ExpandableTableCell;
