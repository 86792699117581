import { AnalyticsCallOptions, logEvent } from 'firebase/analytics';
import { getGoogleAnalytics } from 'services/firebase';

export const logAnalytics = async (
  eventName: string,
  eventParams?:
    | {
        [key: string]: any;
        search_term?: string | undefined;
      }
    | undefined,
  options?: AnalyticsCallOptions | undefined,
) => {
  const analytics = await getGoogleAnalytics();
  if (analytics) logEvent(analytics, eventName, eventParams, options);
};
