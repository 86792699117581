import { Category, reportTracingInfo } from 'utils/traceid';
import useUser from './useUser';

const useTraceReporting = () => {
  const { user } = useUser();

  //Custom command error logging to Sentry
  window.reportError = () => {
    reportTracingInfo(user, Category.Other, 'Error reported from console');

    //Reset errors
    return 'Succesfully reported to Sentry';
  };
};

export default useTraceReporting;
