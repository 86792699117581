import { ChartDataPoint } from 'generated/graphql';

export const CHART_DEBOUNCE_TIME = 100;
export const CHART_HEIGHT = 450;
export const MOBILE_CHART_HEIGHT = 450;

export interface ValidSeries {
  name: string;
  data: ChartDataPoint[];
}

export enum ChartVariant {
  LIGHT = 'Light',
  DEFAULT = 'Default',
}

export enum ChartTooltipType {
  Simple = 'simple',
  Detailed = 'detailed',
  Drawer = 'drawer',
}

export interface ChartStyleProps {
  strokeWidth: number;
  lineStrokeWidth: number;
  nodeStrokeWidth: number;
  activeNodeStrokeWidth: number;
  strokeDashArray?: string;
  colors: string[];
  legend?: boolean;
}

export const ChartLight: ChartStyleProps = {
  strokeWidth: 0,
  lineStrokeWidth: 1,
  nodeStrokeWidth: 1,
  activeNodeStrokeWidth: 0,
  colors: [
    '#B3CE91',
    '#7FB456',
    '#3D5B8F',
    '#AC5B84',
    '#BC8391',
    '#FEA789',
    '#FF6064',
    '#F2203D',
    '#D09B65',
    '#FFCA70',
    '#D1E2BD',
    '#B2D29A',
    '#AEBAD0',
    '#DEBDCE',
    '#D7B5BD',
    '#FECAB8',
    '#FFA0A2',
    '#F7798B',
    '#E3C3A3',
    '#FFDFA9',
    '#596944',
    '#324822',
    '#192439',
    '#442435',
    '#54393F',
    '#9E5237',
    '#872629',
    '#610D18',
    '#59422A',
    '#916C2D',
  ],
  legend: true,
};

export const ChartDefault: ChartStyleProps = {
  strokeWidth: 0.5,
  lineStrokeWidth: 3,
  nodeStrokeWidth: 2,
  activeNodeStrokeWidth: 2,
  colors: ['#1BA3AD'],
};
