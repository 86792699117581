import { Button, Card, Stack, Typography, alpha } from '@mui/material';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Card as CardData } from 'generated/graphql';

export const MenuItemCard = ({ data, onClick }: { data: CardData; onClick: () => void }) => {
  const showArrow = !data.button.value || data.button.value === '';
  const cardColor = data.color?.toLowerCase();
  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        backgroundColor:
          cardColor === 'primary'
            ? alpha(theme.palette.primary.main, 0.2)
            : cardColor === 'secondary'
              ? alpha(theme.palette.secondary.main, 0.2)
              : '',
        opacity: 0.75,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(0, 2),
        border: `1px solid ${theme.palette.grey[300]}`,
        '&:hover': {
          backgroundColor: data.button.actions[0] ? theme.palette.grey[100] : '',
        },
        marginY: theme.spacing(1),
        height: 90,
        cursor: 'pointer',
      })}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <Stack direction={'column'} sx={{ padding: 0 }}>
        <Typography
          variant="body1"
          sx={(theme) => ({
            color: cardColor === 'error' ? theme.palette.error.main : theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
          })}
        >
          {data.title}
        </Typography>
        <Typography
          variant="body1"
          sx={(theme) => ({ fontWeight: theme.typography.fontWeightRegular, color: theme.palette.grey[800] })}
        >
          {data.description}
        </Typography>
      </Stack>
      {showArrow ? (
        <MdKeyboardArrowRight size={20} />
      ) : (
        <Button
          variant={data.button.variant?.toLowerCase() as any}
          color={(data.button.color?.toLowerCase() as any) || 'primary'}
          disabled={!data.button.actions[0]}
        >
          {data.button.value}
        </Button>
      )}
    </Card>
  );
};

export default MenuItemCard;
