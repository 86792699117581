import { Stack, Typography, Button } from '@mui/material';
import { LegendProps } from 'recharts';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import useIsViewport from 'hooks/useIsViewport';

interface CustomLegendProps extends LegendProps {
  hiddenSeries: any[];
}

const SCROLL_SIZE = 6;

const CustomLegend = (props: CustomLegendProps) => {
  const isMobile = useIsViewport('md');
  const { payload, hiddenSeries, dy } = props;
  const payloadSize = payload?.length ?? 0;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={isMobile || payloadSize > SCROLL_SIZE ? 'space-between' : 'center'}
      flexWrap={!isMobile && payloadSize < SCROLL_SIZE ? 'wrap' : undefined}
      gap={0.5}
      sx={(theme) => ({
        paddingTop: 2,
        marginTop: dy ? `${dy}px` : 0,
        overflowX: isMobile || payloadSize > SCROLL_SIZE ? 'scroll' : 'hidden',
        paddingBottom: payloadSize > SCROLL_SIZE ? 2 : 0,
        '&::-webkit-scrollbar': {
          height: theme.spacing(1),
        },
        scrollbarWidth: theme.spacing(1),
      })}
    >
      {payload?.map((entry: Payload, index: number) => {
        return (
          <Typography key={entry.id} sx={{ textWrap: 'nowrap' }}>
            <Button
              sx={(theme) => ({
                backgroundColor: !hiddenSeries.includes(entry.id) ? entry.color : theme.palette.common.white,
                color: hiddenSeries.includes(entry.id) ? entry.color : theme.palette.common.white,
                borderRadius: theme.spacing(3),
                border: `1px solid ${entry.color}`,
                marginRight: theme.spacing(0.5),
                '&:hover': {
                  backgroundColor: theme.palette.grey[300],
                  border: `1px solid ${theme.palette.grey[300]}`,
                  color: theme.palette.common.white,
                },
              })}
              onClick={(event) => {
                if (props.onClick) props.onClick(entry, index, event);
              }}
            >
              {entry.id}
            </Button>
          </Typography>
        );
      })}
    </Stack>
  );
};

export default CustomLegend;
