import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { MdClose } from 'react-icons/md';

const DEFAULT_HIDE_DURATION = 10000;

const ActionSnackbar = ({
  isOpen,
  title,
  actionTitle,
  onAction,
  onClose,
}: {
  isOpen: boolean;
  title: string;
  actionTitle: string;
  onAction: () => void;
  onClose: () => void;
}) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={DEFAULT_HIDE_DURATION}
      onClose={onClose}
      message={title}
      action={
        <>
          <Button color="secondary" size="small" onClick={onAction}>
            {actionTitle}
          </Button>
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <MdClose size={20} />
          </IconButton>
        </>
      }
    />
  );
};

export default ActionSnackbar;
