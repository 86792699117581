import { Card, Stack, ThemeProvider, Typography, alpha } from '@mui/material';
import { getTheme } from 'services/theming';
import { baseTheme } from 'config/base-theme';
import useUser from 'hooks/useUser';
import { AdminProfile, Card as CardData } from 'generated/graphql';
import BtnIcon from './BtnIcon';
import Button from './Button';
import { useWhiteLabelling } from './WhiteLabellingProvider';

export const ButtonCard = ({ data, onClick }: { data: CardData; onClick: () => void }) => {
  const cardColor = data.color?.toLowerCase();
  const { isSeller, isBuyer, isNileUser } = useUser();
  const { isInputsProfile } = useWhiteLabelling();

  const getButtonTheme = () => {
    if (isSeller && isNileUser) return getTheme(AdminProfile.NileInputs);
    else if (isBuyer && isInputsProfile) return getTheme(AdminProfile.Nile);
    else return baseTheme;
  };

  return (
    <ThemeProvider theme={getButtonTheme()}>
      <Card
        elevation={0}
        sx={(theme) => ({
          backgroundColor:
            cardColor === 'primary'
              ? alpha(theme.palette.primary.main, 0.2)
              : cardColor === 'secondary'
                ? alpha(theme.palette.secondary.main, 0.2)
                : '',
          marginY: cardColor ? theme.spacing(2) : theme.spacing(1),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: cardColor === '' ? 0 : theme.spacing(2),
        })}
      >
        {data.title && (
          <Stack direction="row" sx={(theme) => ({ alignItems: 'center', marginBottom: theme.spacing(1) })}>
            {data.icon && <BtnIcon icon={data.icon} />}
            <Stack>
              <Typography
                variant="body1"
                sx={(theme) => ({ margin: 0, fontWeight: theme.typography.fontWeightMedium })}
              >
                {data.title}
              </Typography>
              {data.description && <Typography variant="caption">{data.description}</Typography>}
            </Stack>
          </Stack>
        )}
        <Button
          variant={data.button.variant?.toLowerCase() as any}
          color={(data.button.color?.toLowerCase() as any) || 'primary'}
          startIcon={<BtnIcon icon={data.button.icon} />}
          fullWidth={data.button.variant?.toLowerCase() === 'text' ? false : true}
          onClick={(event) => {
            event.stopPropagation();
            onClick();
          }}
        >
          {data.button.value}
        </Button>
      </Card>
    </ThemeProvider>
  );
};

export default ButtonCard;
