import { FaCreditCard, FaTruck } from 'react-icons/fa';
import {
  MdDescription,
  MdCheck,
  MdReceipt,
  MdTransform,
  MdEdit,
  MdWarning,
  MdChat,
  MdLink,
  MdLinkOff,
  MdPieChart,
  MdDelete,
  MdLogout,
  MdFileDownload,
  MdFileUpload,
  MdOutlineReceiptLong,
} from 'react-icons/md';
import { ButtonIcon } from 'generated/graphql';
import FreshProduceIcon from './ProductIcon/fresh-produce.svg?react';
import InputsIcon from './ProductIcon/inputs.svg?react';
import ProfileSwitch from './ProfileSwitch';
import ChepImage from './chep-logo.png';

const BtnIcon = ({ icon }: { icon: ButtonIcon }) => {
  switch (icon) {
    case ButtonIcon.Chat:
      return <MdChat />;
    case ButtonIcon.Document:
      return <MdDescription />;
    case ButtonIcon.Checkmark:
      return <MdCheck />;
    case ButtonIcon.Transform:
      return <MdTransform />;
    case ButtonIcon.Receipt:
      return <MdReceipt />;
    case ButtonIcon.Card:
      return <FaCreditCard />;
    case ButtonIcon.Edit:
      return <MdEdit />;
    case ButtonIcon.UploadFile:
      return <MdFileUpload />;
    case ButtonIcon.DownloadFile:
      return <MdFileDownload />;
    case ButtonIcon.Truck:
      return <FaTruck />;
    case ButtonIcon.Warning:
      return <MdWarning />;
    case ButtonIcon.Link:
      return <MdLink />;
    case ButtonIcon.UnLink:
      return <MdLinkOff />;
    case ButtonIcon.PieChart:
      return <MdPieChart />;
    case ButtonIcon.Trash:
      return <MdDelete />;
    case ButtonIcon.Logout:
      return <MdLogout />;
    case ButtonIcon.Report:
      return <MdOutlineReceiptLong />;
    case ButtonIcon.ProfileSwitch:
      return <ProfileSwitch />;
    case ButtonIcon.FreshProduce:
      return <FreshProduceIcon width={24} height={24} />;
    case ButtonIcon.Inputs:
      return <InputsIcon width={24} height={24} />;
    case ButtonIcon.Chep:
      return <img src={ChepImage} width={76} height={21} alt="" />;
    default:
      return null;
  }
};

export default BtnIcon;
