import { ButtonProps, SxProps, Box } from '@mui/material';
import { ReactNode, useState } from 'react';
import { MdInfo } from 'react-icons/md';
import { BaseTheme } from 'config/base-theme';
import LightTooltip from './LightTooltip';

const LEAVE_TOUCH_DELAY = 7000;
const ENTER_TOUCH_DELAY = 200;

const MoreInfoTooltip = ({
  description,
  color,
  sx,
  children,
}: {
  description: ReactNode;
  sx?: SxProps<BaseTheme>;
  color?: ButtonProps['color'];
  children?: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  return (
    <LightTooltip
      enterTouchDelay={ENTER_TOUCH_DELAY}
      leaveTouchDelay={LEAVE_TOUCH_DELAY}
      onOpen={handleOpen}
      onClose={handleClose}
      open={isOpen}
      title={description}
    >
      <Box
        onMouseOver={handleOpen}
        onClick={handleOpen}
        sx={[
          (theme) => ({
            display: 'inline-flex',
            padding: theme.spacing(0, 0.5, 0.5),
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {children ?? <Box size={20} color={color} aria-label="More information" component={MdInfo} />}
      </Box>
    </LightTooltip>
  );
};

export default MoreInfoTooltip;
