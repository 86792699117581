import { useCallback } from 'react';
import { clearStore } from 'services/idb-store';
import { useGraphState } from 'components/GraphProvider';
import { useSwitchUserProfileMutation } from 'generated/graphql';
import useUser from './useUser';

export const useSwitchProfile = () => {
  const { resetClient } = useGraphState();
  const { onRefreshUser } = useUser();
  const [, switchProfile] = useSwitchUserProfileMutation();
  return useCallback(
    async (profileId: string) => {
      const response = await switchProfile({
        id: profileId,
      });
      if (response.error) {
        return response.error;
      } else {
        resetClient();
        await clearStore();
        await onRefreshUser();
      }
    },
    [switchProfile, onRefreshUser, resetClient],
  );
};
