import { LoadingButton } from '@mui/lab';
import { Button as MuiButton, ButtonGroup } from '@mui/material';
import { MouseEvent, useState } from 'react';
import useHandleCustomSchemeAndQuery from 'hooks/useHandleCustomSchemeAndQuery';
import { Action, Button } from 'generated/graphql';
import ActionList from './ActionList';
import type { FormDialogState } from './FormDialog';
import LazyFormDialog from './LazyFormDialog';
import { defaultFormDialogState } from './default-form-dialog-state';

const ActionButton = ({ button, onRefresh }: { button: Button; onRefresh?: () => void }) => {
  const [formDialog, setFormDialog] = useState<FormDialogState>(defaultFormDialogState);
  const [buttonActionsOpen, setButtonActionsOpen] = useState<Button | undefined>(undefined);

  const { handleSchemeOrQuery, isLoading } = useHandleCustomSchemeAndQuery(onRefresh);

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (button.actions.length > 1) {
      setButtonActionsOpen(button);
      return;
    }
    const url = button.actions[0]?.url ?? '';
    const query = button.actions[0]?.query ?? '';
    handleSchemeOrQuery(url, query, { setFormDialog: setFormDialog });
  };

  if (button.actions.length === 0) return null;

  const buttonDescription = button.actions[0].description;

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="inherit"
        sx={(theme) => ({
          borderRadius: 0,
          borderColor: theme.palette.grey[300],
          boxShadow: 'none',
        })}
      >
        {buttonDescription && (
          <MuiButton
            variant="outlined"
            color={button.color.toLowerCase() as 'primary' | 'secondary'}
            size="small"
            disabled={isLoading}
            sx={(theme) => ({
              whiteSpace: 'nowrap',
              borderColor: 'inherit',
              backgroundColor: theme.palette.common.white,
            })}
            onClick={handleClick}
          >
            {buttonDescription}
          </MuiButton>
        )}
        <LoadingButton
          loading={isLoading}
          size="small"
          variant={button.variant.toLowerCase() as 'text' | 'outlined' | 'contained'}
          color={button.color.toLowerCase() as 'primary' | 'secondary'}
          onClick={handleClick}
        >
          {button.value || button.actions[0].value}
        </LoadingButton>
      </ButtonGroup>

      {buttonActionsOpen && (
        <ActionList
          open={!!buttonActionsOpen}
          button={buttonActionsOpen}
          onClose={(action?: Action) => {
            setButtonActionsOpen(undefined);
            if (!action) return;
            handleSchemeOrQuery(action.url, action.query, { setFormDialog: setFormDialog });
          }}
        />
      )}

      <LazyFormDialog
        type={formDialog.type}
        editIDs={formDialog.editIDs}
        open={formDialog.open}
        onClose={() => {
          setFormDialog(defaultFormDialogState);
        }}
        onSubmit={() => {
          setFormDialog(defaultFormDialogState);
          if (onRefresh) onRefresh();
        }}
      />
    </>
  );
};

export default ActionButton;
