import { useEffect, useState } from 'react';
import NetworkLoading from './NetworkLoading';

const DELAY = 300;

export const DelayedFallback = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(() => setShow(true), DELAY);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!show) return null;
  return <NetworkLoading bottom={-5} />;
};
