import { useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export const Hide = ({
  direction = 'down',
  breakpoint = 'md',
  children,
}: {
  children: ReactNode;
  direction?: 'up' | 'down';
  breakpoint?: 'sm' | 'md' | 'lg';
}) => {
  const theme = useTheme();
  const current = direction === 'down' ? theme.breakpoints.down(breakpoint) : theme.breakpoints.up(breakpoint);
  const isMatch = useMediaQuery(current);
  if (isMatch) {
    return null;
  }
  return <>{children}</>;
};
