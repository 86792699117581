import { ExpandableTable as ExpandableTableType, TableCellType } from 'generated/graphql';

export const updateExpandableTableFields = (
  table: ExpandableTableType,
  cellIndex: number,
  rowId: string,
  newValue: string,
): ExpandableTableType => ({
  ...table,
  groups: table.groups.map((group) => ({
    ...group,
    rows: group.rows.map((row) => ({
      ...row,
      cells: row.cells.map((cell, i) => ({
        ...cell,
        value: row.id === rowId && i === cellIndex ? newValue : cell.value,
      })),
    })),
  })),
});

export const getTableFieldValues = (
  table: ExpandableTableType,
  columnCode: string,
): { rowId: string; value: string }[] => {
  const columnIndex = table.columns.findIndex(
    (column) =>
      column.code === columnCode &&
      (column.cellType === TableCellType.Input || column.cellType === TableCellType.InputCurrency),
  );
  if (columnIndex === -1) {
    return [];
  }
  return table.groups
    .flatMap((group) => group.rows)
    .map((row) => ({
      rowId: row.id,
      value: row.cells[columnIndex].value,
    }));
};

const table = {
  updateExpandableTableFields,
  getTableFieldValues,
};

export default table;
