import { baseTheme } from 'config/base-theme';
import { isFreshmarkEnabled } from 'config/features';
import { freshmarkTheme } from 'config/freshmark-theme';
import { inputsTheme } from 'config/inputs-theme';
import { villaTheme } from 'config/villa-theme';
import { AdminProfile } from 'generated/graphql';
import isCustomScheme from '../utils/is-custom-scheme';

export enum ThemeVariant {
  FreshMark = AdminProfile.FreshMark,
  Nile = AdminProfile.Nile,
  Inputs = AdminProfile.NileInputs,
  Villa = AdminProfile.Villa,
}

export const NILE_ADMIN_PROFILE_ID = 1;
export const FRESHMARK_ADMIN_PROFILE_ID = 2;
export const INPUTS_ADMIN_PROFILE_ID = 3;
export const VILLA_ADMIN_PROFILE_ID = 4;

export const adminProfileToIdMap: { [key: string]: number } = {
  [AdminProfile.Nile]: NILE_ADMIN_PROFILE_ID,
  [AdminProfile.FreshMark]: FRESHMARK_ADMIN_PROFILE_ID,
  [AdminProfile.NileInputs]: INPUTS_ADMIN_PROFILE_ID,
  [AdminProfile.Villa]: VILLA_ADMIN_PROFILE_ID,
};

export const themeVariantToAdminProfileIdMap = {
  [ThemeVariant.Nile]: NILE_ADMIN_PROFILE_ID,
  [ThemeVariant.FreshMark]: FRESHMARK_ADMIN_PROFILE_ID,
  [ThemeVariant.Inputs]: INPUTS_ADMIN_PROFILE_ID,
  [ThemeVariant.Villa]: VILLA_ADMIN_PROFILE_ID,
};

export const getAdminProfileFromVariant = () => {
  const adminProfileID = themeVariantToAdminProfileIdMap[getThemeVariant()]?.toString();
  return adminProfileID && adminProfileID.length > 0 ? adminProfileID : NILE_ADMIN_PROFILE_ID.toString();
};

export const getThemeVariant = (
  location: {
    pathname: string;
    hash: string;
  } = window.location,
) => {
  // The hash fragment my contain a search string, thus we only check the start segment.
  if (location.pathname.includes('freshmark') && isFreshmarkEnabled) {
    return ThemeVariant.FreshMark;
  }
  if (location.pathname.includes('inputs')) {
    return ThemeVariant.Inputs;
  }
  if (window.location.origin.includes('villa') || location.pathname.includes('villa')) {
    return ThemeVariant.Villa;
  }
  return ThemeVariant.Nile;
};

export const getThemeClassName = () => {
  const themeVariant = getThemeVariant();
  if (themeVariant === ThemeVariant.FreshMark && isFreshmarkEnabled) return 'freshmark';
  if (themeVariant === ThemeVariant.Inputs) return 'inputs';
  if (themeVariant === ThemeVariant.Villa) return 'villa';
  return 'nile';
};

export const getRoutePrefix = () => {
  const variant = getThemeVariant();
  if (variant === ThemeVariant.FreshMark && isFreshmarkEnabled) return '/freshmark';
  if (variant === ThemeVariant.Inputs) return '/inputs';
  if (variant === ThemeVariant.Villa) return '/villa';
  return '';
};

export const getURLTheme = (url?: string) => {
  const path = url || getRoutePrefix();
  if (path.includes('freshmark') && isFreshmarkEnabled) return freshmarkTheme;
  if (path.includes('inputs')) return inputsTheme;
  if (path.includes('villa')) return villaTheme;
  return baseTheme;
};

export const getTheme = (variant: AdminProfile) => {
  if (variant === AdminProfile.FreshMark && isFreshmarkEnabled) return freshmarkTheme;
  if (variant === AdminProfile.NileInputs) return inputsTheme;
  if (variant === AdminProfile.Villa) return villaTheme;
  return baseTheme;
};

export const getPrefixedRoute = (url?: string) => {
  if (isCustomScheme(url!)) {
    // custom scheme so we don't want the browser to redirect
    return '';
  }

  const prefix = getRoutePrefix();

  if (url?.startsWith('#')) {
    return url.replace('#', `${prefix}`);
  }

  if (url?.startsWith('/#/')) {
    return url.replace('/#/', `${prefix}/`);
  }

  if (url?.startsWith('/')) {
    return `${prefix}${url}`;
  }

  return url as string;
};

export const getPlatformTitle = () => {
  const themeVariant = getThemeVariant();
  return themeVariant === ThemeVariant.Villa ? 'Villa' : 'Nile';
};
