import { getIdTokenResult, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import {
  useContext,
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { logout } from 'services/auth';
import { User, auth } from 'services/firebase';
import { NILE_ADMIN_PROFILE_ID } from 'services/theming';
import { useGraphState } from 'components/GraphProvider';
import { Role } from 'generated/graphql';

export const ROBOT_TOKEN_EMAIL = 'robot@nile.ag';

export enum UserType {
  Admin = 'Admin',
  Buyer = 'Buyer',
  Seller = 'Seller',
}

export enum UserStatus {
  Registering = 'Registering',
  PendingApproval = 'PendingApproval',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum UserTheme {
  Dark = 'Dark',
  Light = 'Light',
  System = 'System',
}
export interface AuthUser {
  name: string;
  userId: string;
  theme: UserTheme;
  status: UserStatus;
  email: string;
  intercomUserHash: string;
  multiProfile: boolean;
  profileId: number;
  adminProfileId: number;
  userType: UserType;
  hubId: number;
  roles: Role[];
  emailVerified: boolean;
}
export interface UserClaims {
  name: string;
  profileId: number;
  admin_profile_id: number;
  userType: UserType;
  email: string;
  hubId: number;
  status: UserStatus;
  multiProfile: boolean;
  user_id: string;
  theme: UserTheme;
  intercomUserHash: string;
  roles: Role[];
  email_verified: boolean;
}

export const defaultRobotClaims = {
  name: 'Robot',
  email: ROBOT_TOKEN_EMAIL,
  userType: UserType.Admin,
  profileId: 1,
  admin_profile_id: 1,
  status: UserStatus.Approved,
  theme: UserTheme.Light,
  user_id: '',
  intercomUserHash: '',
  multiProfile: false,
  hubId: 1,
  roles: [],
};

export const getUserFromParsedIdToken = (parsedToken: any): AuthUser => {
  const claims = parsedToken?.claims as UserClaims;

  return {
    name: claims.name,
    email: claims.email,
    userType: claims.userType,
    profileId: claims.profileId,
    adminProfileId: claims.admin_profile_id ?? NILE_ADMIN_PROFILE_ID,
    hubId: claims.hubId,
    userId: claims.user_id,
    theme: claims.theme,
    status: claims.status,
    intercomUserHash: claims.intercomUserHash,
    multiProfile: claims.multiProfile,
    roles: claims.roles,
    emailVerified: claims.email_verified,
  };
};

export const UserContext = createContext<null | {
  user: AuthUser | null;
  setUser: Dispatch<SetStateAction<AuthUser | null>>;
  isAdmin: boolean;
  isNileUser: boolean;
  isSeller: boolean;
  isBuyer: boolean;
  isRegistering: boolean;
  isInitialised: boolean;
  isPendingReview: boolean;
  onLogout: () => Promise<void>;
  onRefreshUser: () => Promise<AuthUser | undefined>;
  hasRole: (role: Role) => boolean;
}>(null);

export const UserProvider = ({ children, initial = null }: { children: ReactNode; initial?: null | AuthUser }) => {
  const [isInitialised, setIsInitialised] = useState(false);
  const [user, setUser] = useState<null | AuthUser>(initial);
  const { resetClient } = useGraphState();
  const isNileUser = user?.adminProfileId === NILE_ADMIN_PROFILE_ID;
  const isRegistering = user?.status === undefined || user?.status === UserStatus.Registering;
  const isPendingReview = !isRegistering && user?.status !== UserStatus.Approved;

  const onTokenChange = useCallback(
    async (newUser: User | null) => {
      if (newUser) {
        const result = await getIdTokenResult(newUser);
        setUser(getUserFromParsedIdToken(result));
      } else {
        setUser(null);
      }
      setIsInitialised(true);
    },
    [setUser],
  );

  const handleLogout = useCallback(async () => {
    resetClient();
    setUser(null);
    await logout();
  }, [resetClient]);

  const handleRefreshUser = useCallback(async () => {
    try {
      if (!auth.currentUser) return;

      const authUser = await getIdTokenResult(auth.currentUser, true);
      const parsedUser = getUserFromParsedIdToken(authUser);
      setUser(parsedUser);
      return parsedUser;
    } catch {}
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, onTokenChange);
    onIdTokenChanged(auth, onTokenChange);
  }, [onTokenChange]);

  useEffect(() => {
    window.addEventListener('focus', handleRefreshUser);
    window.addEventListener('visibilitychange', handleRefreshUser);
    return () => {
      window.removeEventListener('focus', handleRefreshUser);
      window.removeEventListener('visibilitychange', handleRefreshUser);
    };
  }, [handleRefreshUser]);

  const value = useMemo(() => {
    const hasRole = (role: Role) => !!user?.roles?.includes(role);

    return {
      user,
      setUser,
      isRegistering,
      isPendingReview,
      isAdmin: user?.userType === UserType.Admin,
      isSeller: user?.userType === UserType.Seller,
      isBuyer: user?.userType === UserType.Buyer,
      isNileUser,
      onLogout: handleLogout,
      onRefreshUser: handleRefreshUser,
      isInitialised,
      hasRole,
    };
  }, [user, setUser, handleLogout, isRegistering, isPendingReview, handleRefreshUser, isNileUser, isInitialised]);
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = () => {
  const result = useContext(UserContext);
  if (!result) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return result;
};

export default useUser;
