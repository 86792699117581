import { IconButtonProps, Snackbar, SxProps, useMediaQuery } from '@mui/material';
import Alert from '@mui/material/Alert';
import copy from 'copy-to-clipboard';
import { useState } from 'react';
import { MdFileCopy, MdIosShare, MdShare } from 'react-icons/md';
import IconButton from 'components/IconButton';
import { BaseTheme } from 'config/base-theme';
import { hasRobotToken } from 'utils/hasRobotToken';
import { isIOS } from 'utils/is-ios';

interface ShareLinkProps {
  title?: string;
  url: string;
  className?: string;
  sx?: SxProps<BaseTheme>;
  color?: IconButtonProps['color'];
}

const ShareLink = ({ title, url, className, color = 'primary', sx }: ShareLinkProps) => {
  const [showCopied, setShowCopied] = useState(false);
  const isPrint = useMediaQuery('@media print');
  const canShare = !!navigator.share;

  const handleShare = async () => {
    try {
      await navigator.share({
        title,
        url,
      });
    } catch {
      // User cancelled share or network error
    }
  };

  const handleCopy = () => {
    copy(url);
    setShowCopied(true);
  };

  const isRobot = hasRobotToken();
  const isIOSDevice = isIOS();

  if (isRobot || isPrint) return null;

  return (
    <>
      {canShare ? (
        <IconButton sx={sx} className={className} size="small" onClick={handleShare} color={color}>
          {isIOSDevice ? <MdIosShare size={20} /> : <MdShare size={20} />}
        </IconButton>
      ) : (
        <IconButton sx={sx} className={className} disabled={showCopied} size="small" onClick={handleCopy} color={color}>
          <MdFileCopy size={20} />
        </IconButton>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showCopied}
        autoHideDuration={3000}
        onClose={() => setShowCopied(false)}
      >
        <Alert onClose={() => setShowCopied(false)} severity="success">
          Copied!
        </Alert>
      </Snackbar>
    </>
  );
};

export default ShareLink;
