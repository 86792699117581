import { useEffect } from 'react';
import useUser, { defaultRobotClaims, getUserFromParsedIdToken } from 'hooks/useUser';
import { hasRobotToken } from 'utils/hasRobotToken';

const useRobotToken = () => {
  const { user, setUser } = useUser();

  useEffect(() => {
    const shouldUseRobotToken = hasRobotToken() && !user;

    if (shouldUseRobotToken) {
      setUser(getUserFromParsedIdToken({ claims: defaultRobotClaims }));
    }
  }, [user, setUser]);
};

export default useRobotToken;
