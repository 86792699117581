import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { createContext, useContext, useMemo, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { ThemeVariant, getURLTheme, getThemeVariant } from 'services/theming';
import useIsFreshmarkProfile from 'hooks/useIsFreshmarkProfile';
import useIsInputsProfile from 'hooks/useIsInputsProfile';
import useIsVillaProfile from 'hooks/useIsVillaProfile';
import { useSiteMeta } from 'hooks/useSiteMeta';
import { useThemeMatchCheck } from 'hooks/useThemeMatchCheck';
import useUser from 'hooks/useUser';

const Context = createContext<{
  isVariant: (variants: ThemeVariant[]) => boolean;
  themeVariant: ThemeVariant;
  isInputsProfile: boolean;
  isVillaProfile: boolean;
  isFreshmarkProfile: boolean;
  isNileFreshProfile: boolean;
  features: {
    isSeasonPlannerEnabled: boolean;
    isPricingDashboardEnabled: boolean;
  };
} | null>(null);

export const WhiteLabellingProvider = ({ children }: { children: ReactNode }) => {
  const { isSeller, isBuyer } = useUser();
  const location = useLocation();
  const url = location.pathname;
  const theme = getURLTheme(url);
  const [themeVariant, setThemeVariant] = useState(getThemeVariant());

  useEffect(() => {
    setThemeVariant(getThemeVariant(location));
  }, [location]);

  useSiteMeta();
  useThemeMatchCheck();

  const isInputsProfile = useIsInputsProfile();
  const isVillaProfile = useIsVillaProfile();
  const isFreshmarkProfile = useIsFreshmarkProfile();
  const isNileFreshProfile = !isInputsProfile && !isVillaProfile && !isFreshmarkProfile;

  const value = useMemo(
    () => ({
      themeVariant,
      isVariant: (variants: ThemeVariant[]) => variants.includes(themeVariant),
      isInputsProfile,
      isVillaProfile,
      isFreshmarkProfile,
      isNileFreshProfile,
      features: {
        isSeasonPlannerEnabled: isFreshmarkProfile && isBuyer,
        isPricingDashboardEnabled: isSeller && !(isInputsProfile || isVillaProfile),
      },
    }),
    [themeVariant, isInputsProfile, isVillaProfile, isFreshmarkProfile, isSeller, isBuyer, isNileFreshProfile],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Context.Provider value={value}>{children}</Context.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export const useWhiteLabelling = () => {
  const result = useContext(Context);
  if (!result) throw new Error('useWhiteLabelling must be used within a WhiteLabellingProvider');
  return result;
};
