import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import {
  INPUTS_ADMIN_PROFILE_ID,
  VILLA_ADMIN_PROFILE_ID,
  NILE_ADMIN_PROFILE_ID,
  ThemeVariant,
  getThemeVariant,
} from 'services/theming';
import { hasRobotToken } from 'utils/hasRobotToken';
import { isProd } from '../config/env';
import useUser from './useUser';

export const useThemeMatchCheck = () => {
  const { user, onRefreshUser, isBuyer } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const isInputsTheme = getThemeVariant() === ThemeVariant.Inputs;
  const isVillaTheme = getThemeVariant() === ThemeVariant.Villa;
  const isInputsAdminProfile = user?.adminProfileId === INPUTS_ADMIN_PROFILE_ID;
  const isNileAdminProfile = user?.adminProfileId === NILE_ADMIN_PROFILE_ID;
  const isVillaProfile = user?.adminProfileId === VILLA_ADMIN_PROFILE_ID;

  useEffect(() => {
    const isRobot = hasRobotToken();
    if (location.pathname.includes('/onboard') || isRobot) return;

    const handleRedirect = async () => {
      if (isVillaProfile && !isVillaTheme && !isProd) {
        await onRefreshUser();
        navigate(isBuyer ? '/villa/market' : '/villa/shipments');
      }
      if (isInputsAdminProfile && !isInputsTheme) {
        await onRefreshUser();
        navigate(isBuyer ? '/inputs/market' : '/inputs/shipments');
      }
      if (isNileAdminProfile && (isInputsTheme || isVillaTheme)) {
        await onRefreshUser();
        navigate(isBuyer ? '/market' : '/shipments');
      }
    };

    handleRedirect();
  }, [
    navigate,
    isInputsTheme,
    isInputsAdminProfile,
    isNileAdminProfile,
    onRefreshUser,
    isVillaProfile,
    isVillaTheme,
    location.pathname,
    isBuyer,
  ]);
};
