import { MenuItem, Select } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

const generateWeekOptions = (range = 6) => {
  const weekOptions = [];

  // Generate n weeks before and after the current week
  for (let i = -1 * range; i <= range; i++) {
    const startOfWeek = dayjs().add(i, 'week').startOf('isoWeek');
    const endOfWeek = dayjs().add(i, 'week').endOf('isoWeek');
    const weekLabel = `Week ${startOfWeek.isoWeek()} - ${endOfWeek.add(1, 'week').isoWeek()}`;

    weekOptions.push({
      label: weekLabel,
      value: startOfWeek.format('YYYY-MM-DD'), // Can store the start date as the value
      startDate: startOfWeek,
      endDate: endOfWeek,
    });
  }

  return weekOptions;
};

const WeekSelector = ({
  dateRange,
  range = 6,
}: {
  dateRange: {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
    onDatesChange: (startDate: Dayjs, endDate: Dayjs) => void;
    label?: string;
    onReset: () => void;
  };
  range?: number;
}) => {
  const weekOptions = generateWeekOptions(range);

  const handleWeekChange = (event: any) => {
    const selectedWeek = weekOptions.find((week) => week.value === event.target.value);
    if (selectedWeek) {
      dateRange?.onDatesChange(selectedWeek.startDate, selectedWeek.endDate);
    }
  };

  return (
    <>
      <Select
        labelId="weekSelectorLabelId"
        id="weekSelectorId"
        value={
          weekOptions.some((week) => week.value === dateRange.startDate?.format('YYYY-MM-DD'))
            ? dateRange.startDate?.format('YYYY-MM-DD')
            : ''
        }
        onChange={handleWeekChange}
        displayEmpty
      >
        <MenuItem disabled value="">
          <em>Select a week</em>
        </MenuItem>
        {weekOptions.map((week, index) => (
          <MenuItem key={index} value={week.value}>
            {week.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default WeekSelector;
