import { useCallback } from 'react';
import useSearch from './useSearch';

export enum UrlQueryParam {
  Tab = 't',
  Group = 'g',
  Input = 'i',
  Search = 's',
}

/**
 * Returns two functions, one to get a url param, and one to update one.
 *
 * Identital to useSearch, just number based instead of string based.
 */
const useNumberParams = (): [
  (key: string, fallback?: number) => number | undefined,
  (key: string, value: number) => void,
] => {
  const [getParam, setParam] = useSearch();
  const get = useCallback(
    (key: string, fallback?: number) => {
      const param = getParam(key);
      return param ? parseInt(param, 10) : fallback;
    },
    [getParam],
  );
  const set = useCallback((key: string, value: number) => setParam(key, value.toString()), [setParam]);
  return [get, set];
};

export default useNumberParams;
