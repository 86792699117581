import { Badge, ThemeProvider, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { MdOutlineCompareArrows } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { getTheme } from 'services/theming';
import { baseTheme } from 'config/base-theme';
import useUser from 'hooks/useUser';
import { AdminProfile, FormType } from 'generated/graphql';
import LazyFormDialog from './LazyFormDialog';
import freshProduce from './ProductIcon/fresh-produce.svg';
import inputs from './ProductIcon/inputs.svg';
import { useWhiteLabelling } from './WhiteLabellingProvider';

const PROFILE_SWITCH_DIAMETER = 5;

export enum ProfileSwitchSize {
  Default = 'default',
  Small = 'small',
}

const ProfileSwitch = ({
  size = ProfileSwitchSize.Default,
  isButton = false,
}: {
  size?: ProfileSwitchSize;
  isButton?: boolean;
}) => {
  const { isSeller, isBuyer, isNileUser } = useUser();
  const { isInputsProfile } = useWhiteLabelling();
  const iconSRC = isInputsProfile ? inputs : freshProduce;
  const [showManageProfileDialog, setShowProfileDialog] = useState(false);
  const location = useLocation();
  const showArrows = (isButton && isSeller && isNileUser) || (isButton && isBuyer && isInputsProfile);
  const showBadge = !isButton || showArrows;

  useEffect(() => {
    setShowProfileDialog(false);
  }, [location]);

  const getBadgeTheme = () => {
    if (isSeller && isNileUser && isButton) return getTheme(AdminProfile.NileInputs);
    else if (isBuyer && isInputsProfile && isButton) return getTheme(AdminProfile.Nile);
    else return baseTheme;
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          borderRadius: '50%',
          backgroundColor: isInputsProfile ? theme.palette.secondary.main : theme.palette.primary.light,
          width: theme.spacing(PROFILE_SWITCH_DIAMETER),
          height: theme.spacing(PROFILE_SWITCH_DIAMETER),
          minWidth: theme.spacing(PROFILE_SWITCH_DIAMETER),
          minHeight: theme.spacing(PROFILE_SWITCH_DIAMETER),
          transform: size === ProfileSwitchSize.Small ? 'scale(0.7)' : 'scale(0.9)',
          cursor: isButton ? 'pointer' : 'default',
        })}
        onClick={() => {
          if (isButton) setShowProfileDialog(true);
        }}
      >
        <ThemeProvider theme={getBadgeTheme()}>
          {showBadge ? (
            <Badge
              overlap="circular"
              badgeContent={showArrows ? <MdOutlineCompareArrows size={16} /> : ''}
              sx={(theme) => ({
                '& > span': {
                  transform: isButton ? 'translate(90%, 100%)' : 'scale(0.5) translate(150%, 250%)',
                  background: showArrows
                    ? isNileUser
                      ? theme.palette.secondary.light
                      : theme.palette.primary.light
                    : theme.palette.primary.main, // TODO: Confirm colouring here
                  border: isButton ? 'inherit' : `3px solid ${theme.palette.background.paper}`,
                  padding: 0,
                },
              })}
            >
              <Box
                component="img"
                src={iconSRC}
                sx={{
                  width: 30,
                  height: 30,
                  transform: 'translate(15%, 15%)',
                }}
              />
            </Badge>
          ) : (
            <Box
              component="img"
              src={iconSRC}
              sx={{
                width: 30,
                height: 30,
                transform: 'translate(15%, 15%)',
              }}
            />
          )}
        </ThemeProvider>
      </Box>
      <LazyFormDialog
        type={FormType.ProfileQuickActions}
        editIDs={[]}
        open={showManageProfileDialog}
        onClose={() => {
          setShowProfileDialog(false);
        }}
        hideDialogActions
      />
    </>
  );
};

export default ProfileSwitch;
