import { TableCell, TableCellProps } from '@mui/material';
import { mergeSx } from 'utils/merge-sx';

const ExpandableActionCell = ({ sx, ...rest }: TableCellProps) => (
  <TableCell
    {...rest}
    sx={mergeSx(
      (theme) => ({
        '&.MuiTableCell-root': {
          borderRight: 0,
          borderLeft: 0,
          padding: theme.spacing(0.75, 1.5),
          [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0.75, 1, 0.75, 0),
          },
        },
      }),
      sx ?? {},
    )}
  />
);

export default ExpandableActionCell;
