import lazy from '@fatso83/retry-dynamic-import/react-lazy';
import { Suspense } from 'react';

const FileViewer = lazy(() => import('./FileViewer'));

interface FilesProps {
  files: string[];
  reference?: string;
}

const Files = ({ files, reference }: FilesProps) => {
  return (
    <Suspense fallback={null}>
      {files.map((src, index) => (
        <FileViewer key={src} src={src} reference={reference} fileIndex={index}>
          File {index + 1}
        </FileViewer>
      ))}
    </Suspense>
  );
};

export default Files;
