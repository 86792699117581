import { FormType } from 'generated/graphql';
import type { FormDialogState } from './FormDialog';

export const defaultFormDialogState: FormDialogState = {
  open: false,
  editIDs: [],
  eTags: [],
  type: FormType.Unknown,
  addButton: false,
  intent: undefined,
  undoTitle: undefined,
  confirmDialogVariant: 'default',
};
