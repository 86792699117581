import { useMediaQuery } from '@mui/material';
import useUser, { UserTheme } from './useUser';

const useIsDarkMode = () => {
  const { user } = useUser();
  const isDarkPreferredSystemTheme = useMediaQuery('(prefers-color-scheme: dark)');
  return (user?.theme === UserTheme.System && isDarkPreferredSystemTheme) || user?.theme === UserTheme.Dark;
};

export default useIsDarkMode;
