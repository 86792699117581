import { getAnalytics, isSupported as isAnalyticsSupported } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth, User as AuthUser } from 'firebase/auth';
import { getMessaging, isSupported as isMessagingSupported } from 'firebase/messaging';
import { getStorage } from 'firebase/storage';

const config = {
  projectId: import.meta.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: import.meta.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: import.meta.env.REACT_APP_FIREBASE_DB_URL,
  storageBucket: import.meta.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  apiKey: import.meta.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  messagingSenderId: import.meta.env.REACT_APP_FIREBASE_SENDER_ID,
  measurementId: import.meta.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(config);
const auth = getAuth(firebaseApp);

const getFirebaseMessaging = async () => {
  if (await isMessagingSupported()) {
    return getMessaging(firebaseApp);
  }
  return null;
};

const getGoogleAnalytics = async () => {
  if (await isAnalyticsSupported()) {
    return getAnalytics(firebaseApp);
  }
  return null;
};

const qcStorage = getStorage(firebaseApp, import.meta.env.REACT_APP_FIREBASE_QC_STORAGE_BUCKET);
const storage = getStorage(firebaseApp);

export type User = AuthUser;
export { getGoogleAnalytics, getFirebaseMessaging, auth, firebaseApp, storage, qcStorage };
export default firebaseApp;
