import { useEffect, useState } from 'react';

const useVersionedQuery = (query: any, options: any) => {
  const [version, setVersion] = useState(0);
  const [result, refresh] = query(options);

  useEffect(() => {
    setVersion(new Date().getTime());
  }, [query]);

  const handleRefresh = () => {
    setVersion(new Date().getTime());
    refresh();
  };

  return {
    result,
    refresh: handleRefresh,
    version,
  };
};

export default useVersionedQuery;
