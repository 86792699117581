import { Dialog, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import { MdClose } from 'react-icons/md';
import ImageSlider from './ImageSlider';

export interface ImagesDialogState {
  open: boolean;
  title: string;
  images: string[];
}

export const defaultImagesDialogState: ImagesDialogState = {
  open: false,
  title: '',
  images: [],
};

const ImageSliderDialog = ({
  title,
  images,
  open,
  onClose,
}: {
  title?: string;
  images: string[];
  open: boolean;
  onClose: () => void;
}) => {
  if (images.length < 0) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {title}
          <IconButton onClick={onClose}>
            <MdClose width={24} height={24} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <ImageSlider urls={images} name={title ?? ''} />
      </DialogContent>
    </Dialog>
  );
};

export default ImageSliderDialog;
