import { Button as ButtonType, ButtonVariant, ButtonColor, ButtonIcon } from 'generated/graphql';

export const createButtonType = (button: Partial<ButtonType>): ButtonType => ({
  __typename: 'Button',
  variant: ButtonVariant.Text,
  color: ButtonColor.Primary,
  icon: ButtonIcon.Card,
  value: '',
  description: '',
  selected: false,
  actions: [
    {
      value: '',
      url: '',
      query: '',
      group: [],
      code: '',
      description: '',
    },
  ],
  ...button,
});
