import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { getMetadata, ref } from 'firebase/storage';
import prettyBytes from 'pretty-bytes';
import { useMemo, useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { MdOutlinePictureAsPdf } from 'react-icons/md';
import { storage } from 'services/firebase';
import BtnIcon from 'components/BtnIcon';
import { ButtonIcon } from 'generated/graphql';

export const ImageUploadPreview = ({
  fileSRC,
  reference = '',
  onRemove,
  onClick,
}: {
  fileSRC: string;
  reference?: string;
  onRemove: (url: string) => void;
  onClick: (src: string) => void;
}) => {
  const [name, setName] = useState('');
  const [size, setSize] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isPDF, setIsPDF] = useState(false);

  const storageRef = ref(storage, fileSRC);
  useMemo(async () => {
    return await getMetadata(storageRef)
      .then((metadata) => {
        setName(
          (metadata?.customMetadata?.originalName ?? '') +
            (reference.length > 0 ? `_${reference}` : '') +
            (parseInt(metadata?.customMetadata?.fileNumber ?? '0') > 1
              ? ` (${metadata?.customMetadata?.fileNumber})`
              : '') +
            (`.${metadata?.customMetadata?.extension}` ?? ''),
        );
        setSize(metadata.size);
        if (metadata?.customMetadata?.extension === 'pdf') setIsPDF(true);
      })
      .catch(() => {
        setName('');
        setSize(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [storageRef, reference]);

  if (!fileSRC || fileSRC?.length === 0) return null;

  return (
    <Stack
      direction={'row'}
      gap={1}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={(theme) => ({
        marginTop: theme.spacing(1),
      })}
    >
      <Stack direction={'row'} gap={1} sx={{ cursor: 'pointer' }} onClick={() => onClick(fileSRC)}>
        {loading ? (
          <Skeleton variant="rounded" height={64} width={64} />
        ) : isPDF ? (
          <Box
            sx={(theme) => ({
              minWidth: theme.spacing(8),
              maxWidth: theme.spacing(8),
              height: theme.spacing(8),
              borderRadius: theme.spacing(1),
              opacity: 0.3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            })}
          >
            <MdOutlinePictureAsPdf size={40} />
          </Box>
        ) : (
          <Box
            component="img"
            src={fileSRC}
            loading="eager"
            sx={(theme) => ({
              minWidth: theme.spacing(8),
              maxWidth: theme.spacing(8),
              height: theme.spacing(8),
              objectFit: 'cover',
              borderRadius: theme.spacing(1),
              opacity: 0.3,
              backgroundColor: theme.palette.grey[200], // Grey background to ensure smooth transition from grey Skeleton component
            })}
          />
        )}
        {!loading && (
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            sx={(theme) => ({
              minWidth: theme.spacing(8),
              maxWidth: theme.spacing(8),
              height: theme.spacing(8),
              marginLeft: theme.spacing(-9),
              borderRadius: theme.spacing(1),
            })}
          >
            <FaEye />
          </Stack>
        )}
        <Stack gap={loading ? 1 : 0} sx={{ justifyContent: 'center' }}>
          {loading ? <Skeleton variant="rectangular" width="100px" height="15px" /> : <Typography>{name}</Typography>}
          {loading ? (
            <Skeleton variant="rectangular" width="100px" height="15px" />
          ) : (
            <Typography variant={'body2'} sx={(theme) => ({ color: theme.palette.grey[700] })}>
              {prettyBytes(size)}
            </Typography>
          )}
        </Stack>
      </Stack>
      <IconButton size="medium" sx={{ height: 'fit-content' }} onClick={() => onRemove(fileSRC)}>
        <BtnIcon icon={ButtonIcon.Trash} />
      </IconButton>
    </Stack>
  );
};

export default ImageUploadPreview;
