const downloadCSV = (csv: string, filename: string) => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  fakeLink.setAttribute('href', URL.createObjectURL(blob));
  fakeLink.setAttribute('download', `${filename}.csv`);
  fakeLink.click();
  document.body.removeChild(fakeLink);
};

export default downloadCSV;
