import { ReactNode } from 'react';
import { UseQueryState } from 'urql';
import NetworkError from 'components/NetworkError';
import NetworkLoading from 'components/NetworkLoading';

interface QueryResultProps {
  result: UseQueryState;
  empty?: string;
  children: ReactNode | ((data: any) => ReactNode);
  hideLoadingHero?: boolean;
  showLoaderWhileRefreshing?: boolean;
  isProgressiveLoadingEnabled?: boolean;
  handleError?: boolean;
}

const QueryResult = ({
  result,
  empty,
  children,
  hideLoadingHero = false,
  showLoaderWhileRefreshing = false,
  isProgressiveLoadingEnabled = false,
  handleError = true,
}: QueryResultProps) => {
  const { data, fetching, error } = result;
  const showLoading = (fetching && showLoaderWhileRefreshing) || (fetching && !data);

  if (!isProgressiveLoadingEnabled && showLoading) {
    return <NetworkLoading hideHero={hideLoadingHero} />;
  }

  if (error && handleError) {
    return <NetworkError message={error.message} />;
  }

  if (!error && empty && !fetching && !data) {
    return <NetworkError message={empty} />;
  }

  if (typeof children === 'function') {
    const result = { ...data, fetching, error, isError: !!error };
    return <>{children(result)}</>;
  }

  return <>{children}</>;
};

export default QueryResult;
