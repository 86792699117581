import { Badge, BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { useState } from 'react';
import { IconType } from 'react-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { getRoutePrefix } from 'services/theming';
import { BaseTheme } from 'config/base-theme';
import { useBuyersMarket } from 'hooks/useBuyersMarket';
import { useBuyersMarketCart } from 'hooks/useBuyersMarketCart';
import useCartInfo from 'hooks/useCartInfo';
import useDetectKeyboardOpen from 'hooks/useDetectKeyboardOpen';
import useIsDarkMode from 'hooks/useIsDarkMode';
import useNavigationItems from 'hooks/useNavigationItems';
import useUser from 'hooks/useUser';

const badgeStyle = (theme: BaseTheme) => ({
  '& > svg': {
    fontSize: '1.6em',
  },
  '& > span': {
    height: 20,
    transform: 'scale(1) translate(120%, -30%)',
    background: theme.palette.success.light,
  },
});
const BottomNavProviderBadge = ({ icon }: { icon: IconType }) => {
  const { cartGroup, carts } = useBuyersMarketCart();
  const { trucks } = useBuyersMarket();

  const { cartGroupCases } = useCartInfo(cartGroup, trucks, carts);

  return (
    <Badge badgeContent={cartGroupCases} sx={badgeStyle}>
      <Box component={icon} sx={{ fontSize: '1.6em' }} />
    </Badge>
  );
};

const BottomNavBadge = ({ icon }: { icon: IconType }) => {
  return (
    <Badge badgeContent={0} sx={badgeStyle}>
      <Box component={icon} sx={{ fontSize: '1.6em' }} />
    </Badge>
  );
};
const BottomNav = () => {
  const isDarkMode = useIsDarkMode();
  const navigate = useNavigate();
  const location = useLocation();

  const prefix = getRoutePrefix();
  const [value, setValue] = useState(location?.pathname ?? `${prefix}/shipments`);
  const isKeyboardOpen = useDetectKeyboardOpen();

  const tabs = useNavigationItems();
  const { user } = useUser();

  if (isKeyboardOpen) {
    return null;
  }

  return (
    <BottomNavigation
      value={value}
      onChange={(_, route) => {
        setValue(route);
        navigate(route);
      }}
      showLabels
      sx={(theme) => ({
        display: 'none',
        borderBottom: 'none',
        backgroundColor: isDarkMode ? theme.palette.primary.main : theme.palette.common.white,
        [theme.breakpoints.down('md')]: {
          display: 'flex',
        },
        '.Mui-selected': {
          color: isDarkMode ? theme.palette.common.white : theme.palette.primary.main,
        },
      })}
    >
      {tabs.map((tab) => {
        const { label, path, icon: Icon } = tab;

        return (
          <BottomNavigationAction
            key={label}
            label={label}
            value={path}
            sx={(theme) => ({
              color: isDarkMode ? theme.palette.grey[400] : theme.palette.primary.main, // Mobile header uses primary.main for color
            })}
            icon={label === 'Cart' && user ? <BottomNavProviderBadge icon={Icon} /> : <BottomNavBadge icon={Icon} />}
          />
        );
      })}
    </BottomNavigation>
  );
};

export default BottomNav;
