import { Box, LinearProgress, Typography } from '@mui/material';
import { AiOutlineClockCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import { baseTheme } from 'config/base-theme';
import LightTooltip from './LightTooltip';

const MIN_PROGRESS_FOR_GRAPHIC = 2;

const BAR_HEIGHT = '20px';
const BAR_RADIUS = '40px';

export enum LabelIconVariant { // Note: Removed None since it currently has no use
  Clock = 'Clock',
}

const getLabelIcon = (type: LabelIconVariant) => {
  switch (type) {
    case LabelIconVariant.Clock:
      return <AiOutlineClockCircle size={20} style={{ margin: baseTheme.spacing(0, 0.5) }} />;
    default:
      return <AiOutlineInfoCircle size={20} />;
  }
};

const CutoffProgressBar = ({
  progress,
  barIcon,
  label,
  labelIcon,
  labelToolTip,
}: {
  progress: number;
  barIcon?: string;
  label?: string;
  labelIcon?: LabelIconVariant;
  labelToolTip?: string;
}) => {
  return (
    <Box sx={(theme) => ({ display: 'flex', flexDirection: 'row', margin: theme.spacing(1, 0, 2) })}>
      <Box sx={{ display: 'flex', flexDirection: 'row', width: `100%`, position: 'relative' }}>
        <LinearProgress
          variant="determinate"
          color="secondary"
          value={progress}
          style={{
            backgroundColor: baseTheme.palette.grey[300],
            borderRadius: BAR_RADIUS,
            width: '100%',
            height: BAR_HEIGHT,
          }}
        />
        {progress > MIN_PROGRESS_FOR_GRAPHIC && (
          <Box
            sx={{
              borderRadius: BAR_RADIUS,
              width: `${progress}%`,
              display: 'flex',
              flexDirection: 'row-reverse',
              height: BAR_HEIGHT,
              position: 'absolute',
              zIndex: '50',
            }}
          >
            <img
              src={barIcon}
              alt=""
              width={32}
              height={20}
              style={{ maxHeight: '22px', maxWidth: 'max-content', position: 'relative', left: '6px' }}
            />
          </Box>
        )}
      </Box>
      {labelIcon && labelToolTip && (
        <LightTooltip title={labelToolTip} arrow>
          <Box>{getLabelIcon(labelIcon)}</Box>
        </LightTooltip>
      )}
      <Typography sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>{label}</Typography>
    </Box>
  );
};

export default CutoffProgressBar;
