import { createTheme, responsiveFontSizes, Shadows, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import componentThemeOverrides from './component-theme-overrides';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }
}

export const roboto = [
  'Roboto',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'sans-serif',
].join(',');

export const nunito = ['Nunito', roboto].join(',');

export type BaseTheme = Theme;

const palette = {
  primary: {
    dark: '#0C3136',
    main: '#054B54',
    light: '#57939B',
  },
  secondary: {
    main: '#1CA3AD',
  },
  info: {
    dark: '#CD2A0A',
    main: '#F64928',
    light: '#F96244',
  },
  warning: {
    main: '#F39512',
    light: '#FACA92',
  },
  background: {
    default: '#F6F6F0',
    paper: '#FFFFFF',
  },
  text: {
    primary: '#07132F',
    secondary: '#666D7D',
  },
  tertiary: {
    dark: '#89BAC0',
    main: '#D3EAEC',
    light: '#F2FBFC',
  },
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1240,
      xl: 1584,
    },
  },
  palette,
  typography: {
    fontSize: 12,
    fontFamily: roboto,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontFamily: nunito,
      fontWeight: 800,
    },
    h2: {
      fontFamily: nunito,
      fontWeight: 800,
    },
    h3: {
      fontFamily: nunito,
      fontWeight: 800,
      fontSize: '1.7rem',
    },
    h4: {
      fontFamily: nunito,
      fontWeight: 800,
      fontSize: '1.5rem',
    },
    h5: {
      fontFamily: nunito,
      fontWeight: 700,
      fontSize: '1.3rem',
    },
    h6: {
      fontFamily: nunito,
      fontWeight: 700,
      fontSize: '1.1rem',
    },
    button: {
      fontFamily: nunito,
      fontWeight: 700,
    },
    subtitle1: {
      fontFamily: roboto,
    },
    subtitle2: {
      fontFamily: roboto,
    },
    body1: {
      fontFamily: roboto,
    },
    body2: {
      fontFamily: roboto,
    },
    caption: {
      color: grey[600],
      fontFamily: nunito,
      fontSize: '0.95em',
      fontWeight: 700,
    },
    overline: {
      fontFamily: roboto,
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
    '0 25px 50px -12px rgb(0 0 0 / 0.25)',
    ...Array(18).fill('none'),
  ] as Shadows,
});

const baseTheme = responsiveFontSizes(createTheme(theme, { components: componentThemeOverrides(theme) }));

export { baseTheme };
