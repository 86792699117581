import { DEFAULT_ID_DELIMITER } from 'services/api-constants';
import { Field, InputType } from 'generated/graphql';

export const mapFieldValuesToId = (fields: Field[]) => {
  return fields.reduce(
    (acc, field) => {
      if (field.type === InputType.Number) {
        acc[`${DEFAULT_ID_DELIMITER}${field.id}`] = Number.parseFloat(
          field.values.length > 0 ? field.values[0] : field.value,
        );
      }
      return acc;
    },
    {} as { [fieldName: string]: number },
  );
};
