import { useClient } from 'urql';
import {
  TableDialogQuery,
  QueryTableDialogArgs,
  TableDialogDocument,
  TableDialogType,
  TableDialog,
} from 'generated/graphql';

const useHandleTableDialog = () => {
  const client = useClient();

  const handleTableDialog = async (
    urlEntity: URL,
    setTableDialog: (value: TableDialog) => void,
    setErrorMessage: (value: string) => void,
  ) => {
    const result = await client
      .query<TableDialogQuery, QueryTableDialogArgs>(
        TableDialogDocument,
        {
          type: urlEntity.searchParams.get('type') as TableDialogType,
          shipmentID: urlEntity.searchParams.get('shipmentID') || '',
        },
        { requestPolicy: 'network-only' },
      )
      .toPromise();
    if (result.error?.message) {
      setErrorMessage(result.error.message);
      return;
    }
    if (!result.data?.tableDialog) {
      return;
    }
    setTableDialog(result.data?.tableDialog as TableDialog);
  };

  return handleTableDialog;
};

export default useHandleTableDialog;
