import { Tooltip, TooltipProps } from '@mui/material';

const LightTooltip = ({ ...rest }: TooltipProps) => (
  <Tooltip
    {...rest}
    sx={(theme) => ({
      '&.MuiTooltip-tooltip': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.grey[700],
        padding: theme.spacing(1, 1.5),
        boxShadow: 2,
        fontSize: '0.875rem',
        maxWidth: 'none',
      },
      '&.MuiTooltip-arrow': {
        color: theme.palette.common.white,
      },
    })}
  />
);

export default LightTooltip;
