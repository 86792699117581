import { InputAdornment, Stack } from '@mui/material';
import { MdArrowDropDown } from 'react-icons/md';
import dayjs from 'services/dayjs';
import CustomDatePicker from 'screens/Products/CustomDatePicker';
import { baseTheme } from 'config/base-theme';
import useDebouncedFunc from 'hooks/useDebouncedFunc';

const endAdornment = (
  <InputAdornment position="start">
    <MdArrowDropDown color={baseTheme.palette.common.black} size={24} />
  </InputAdornment>
);

interface CustomDateRangePickerProps {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  debounceTimeout?: number;
  onStartDateChange: (val: dayjs.Dayjs) => void;
  onEndDateChange: (val: dayjs.Dayjs) => void;
}

const DEBOUNCE_TIME = 1200;

const CustomDateRangePicker = ({
  startDate,
  endDate,
  onStartDateChange,
  debounceTimeout = DEBOUNCE_TIME,
  onEndDateChange,
}: CustomDateRangePickerProps) => {
  const handleEndDateChanged = useDebouncedFunc((val: dayjs.Dayjs) => {
    onEndDateChange(val);
  }, debounceTimeout);

  const handleStartDateChanged = useDebouncedFunc((val: dayjs.Dayjs) => {
    if (val.unix() > endDate.unix()) {
      handleEndDateChanged(val);
    }
    onStartDateChange(val);
  }, debounceTimeout);

  return (
    <Stack direction={'row'} gap={1}>
      <CustomDatePicker
        variant="mui"
        label="Start date"
        endAdornment={endAdornment}
        isLanguageDate
        startDate={startDate}
        onDatePickerChange={handleStartDateChanged}
      />
      <CustomDatePicker
        variant="mui"
        label="End date"
        endAdornment={endAdornment}
        min={startDate}
        isLanguageDate
        startDate={endDate}
        onDatePickerChange={handleEndDateChanged}
      />
    </Stack>
  );
};

export default CustomDateRangePicker;
