import { useMemo } from 'react';
import { Role } from 'generated/graphql';
import useUser from './useUser';

export enum Permission {
  Finance = 'Finance',
  MarketingTools = 'MarketingTools',
  ProductConfig = 'ProductConfig',
  Customers = 'Customers',
  Market = 'Market',
  CHEPLocations = 'CHEPLocations',
  Dashboard = 'Dashboard',
  ProductMarkups = 'ProductMarkups',
}

export enum Scope {
  Read = 'Read',
  Write = 'Write',
}

const GeneralRolePermissions = new Map<Scope, Permission[]>([
  [Scope.Read, [Permission.Market]],
  [Scope.Write, [Permission.Market]],
]);

const AdminRolePermissions = new Map<Scope, Permission[]>([
  [Scope.Read, [Permission.Customers, Permission.MarketingTools, Permission.ProductConfig, Permission.ProductMarkups]],
  [Scope.Write, [Permission.MarketingTools, Permission.ProductMarkups]],
]);

const AdminCustomerRolePermissions = new Map<Scope, Permission[]>([
  [Scope.Read, [Permission.Customers]],
  [Scope.Write, [Permission.Customers]],
]);

const AdminProductRolePermissions = new Map<Scope, Permission[]>([
  [Scope.Read, [Permission.ProductConfig]],
  [Scope.Write, [Permission.ProductConfig]],
]);

const FinanceOwnerRolePermissions = new Map<Scope, Permission[]>([
  [Scope.Read, [Permission.Finance, Permission.CHEPLocations]],
  [Scope.Write, [Permission.Finance, Permission.CHEPLocations]],
]);

const FinanceMemberRolePermissions = new Map<Scope, Permission[]>([
  [Scope.Read, [Permission.Finance]],
  [Scope.Write, [Permission.Finance]],
]);

const ProfileOwnerPermissions = new Map<Scope, Permission[]>([
  [Scope.Read, [Permission.Finance]],
  [Scope.Write, [Permission.Finance]],
]);

const DashboardRolePermissions = new Map<Scope, Permission[]>([
  [Scope.Read, [Permission.Dashboard]],
  [Scope.Write, [Permission.Dashboard]],
]);

const VillaSellerRolePermissions = new Map<Scope, Permission[]>([
  [Scope.Read, [Permission.ProductMarkups]],
  [Scope.Write, [Permission.ProductMarkups]],
]);

const roleHasPermission = (role: Role, permission: Permission, scope: Scope): boolean => {
  switch (role) {
    case Role.Super:
      return true;
    case Role.General:
      return !!GeneralRolePermissions.get(scope)?.includes(permission);
    case Role.Admin:
      return !!AdminRolePermissions.get(scope)?.includes(permission);
    case Role.AdminCustomer:
      return !!AdminCustomerRolePermissions.get(scope)?.includes(permission);
    case Role.AdminProduct:
      return !!AdminProductRolePermissions.get(scope)?.includes(permission);
    case Role.FinanceOwner:
      return !!FinanceOwnerRolePermissions.get(scope)?.includes(permission);
    case Role.FinanceMember:
      return !!FinanceMemberRolePermissions.get(scope)?.includes(permission);
    case Role.ProfileOwner:
      return !!ProfileOwnerPermissions.get(scope)?.includes(permission);
    case Role.Dashboard:
      return !!DashboardRolePermissions.get(scope)?.includes(permission);
    case Role.VillaSeller:
      return !!VillaSellerRolePermissions.get(scope)?.includes(permission);
    default:
      return false;
  }
};

export const useAccess = () => {
  const { user } = useUser();
  return useMemo(
    () => (permission: Permission, scope: Scope) => {
      for (let role of user?.roles ?? []) {
        if (roleHasPermission(role, permission, scope)) {
          return true;
        }
      }
      return false;
    },
    [user],
  );
};
