import dayjs from 'dayjs';
import { createContext, useContext, ReactNode } from 'react';
import useUser from 'hooks/useUser';
import { MarketLive, useMarketLiveSubscription } from 'generated/graphql';

export const MARKET_MORNING_START = dayjs().startOf('day').hour(8).toISOString();
export const MARKET_MORNING_END = dayjs().startOf('day').hour(10).minute(30).toISOString();
export const MARKET_AFTERNOON_START = dayjs().startOf('day').hour(14).toISOString();
export const MARKET_AFTERNOON_END = dayjs().startOf('day').hour(16).minute(30).toISOString();

const GlobalSubscriptionsContext = createContext<
  | {
      marketLive?: MarketLive;
      loading: boolean;
      error?: Error;
    }
  | undefined
>(undefined);

export const GlobalSubscriptionsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user, isInitialised } = useUser();
  const [result] = useMarketLiveSubscription({
    pause: !user?.email || !isInitialised,
  });

  const { data, fetching, error } = result;

  const value = {
    marketLive: data?.marketLive,
    loading: fetching,
    error: error?.networkError,
  };

  return <GlobalSubscriptionsContext.Provider value={value}>{children}</GlobalSubscriptionsContext.Provider>;
};

export const useGlobalSubscriptions = () => {
  const context = useContext(GlobalSubscriptionsContext);
  if (!context) {
    throw new Error('useGlobalSubscriptions must be used within a GlobalSubscriptionsProvider');
  }
  return context;
};
