import { Box, Skeleton, SxProps } from '@mui/material';
import { ReactNode } from 'react';
import { MdCancel } from 'react-icons/md';
import Chip from 'components/Chip';
import { BaseTheme } from 'config/base-theme';
import { mergeSx } from 'utils/merge-sx';
import ProductIcon from './ProductIcon/ProductIcon';

type Color = 'secondary' | 'default' | 'primary' | 'error' | 'info' | 'success' | 'warning';
type Variant = 'default' | 'white';

interface ChipsProps {
  chipSx?: SxProps<BaseTheme>;
  data?: any[];
  valueKey?: string;
  labelKey?: string;
  hasIcon?: boolean;
  notSelectedColor?: Color;
  variant?: Variant;
  onChange?: (id: string) => void;
  onClear?: (label: string) => void;
  selectedColor?: Color;
  showAll?: boolean;
  showClear?: boolean;
  startAdornment?: ReactNode;
  sx?: SxProps<BaseTheme>;
  value?: string | string[];
  fillOnSelection?: boolean;
  scroll?: boolean;
  childrenBeforeChips?: ReactNode;
}

const SHOW_ALL = 'Show All';

const skeletonChipStyle = (theme: BaseTheme) => ({ borderRadius: theme.spacing(8) });

const LoadingChips = () => (
  <Box
    sx={(theme) => ({
      display: 'flex',
      gap: theme.spacing(0.5),
      [theme.breakpoints.down('md')]: {
        flex: '1',
        overflow: 'auto',
      },
    })}
  >
    <Skeleton sx={skeletonChipStyle} variant="rectangular" height={30} width={72} />
    <Skeleton sx={skeletonChipStyle} variant="rectangular" height={30} width={72} />
    <Skeleton sx={skeletonChipStyle} variant="rectangular" height={30} width={72} />
  </Box>
);

const Chips = ({
  chipSx = {},
  data,
  valueKey = undefined,
  labelKey = undefined,
  hasIcon = false,
  variant = 'default',
  notSelectedColor = 'default',
  onChange = () => {},
  onClear = () => {},
  selectedColor = 'secondary',
  showAll = false,
  showClear = false,
  startAdornment = undefined,
  sx = {},
  value,
  fillOnSelection = false,
  scroll = false,
  childrenBeforeChips,
}: ChipsProps) => {
  if (!data) {
    return <LoadingChips />;
  }

  const hasShowAll = showAll && data[0] && typeof data[0] === 'string';
  const isShowAll = value === '' || (Array.isArray(value) && value?.includes(''));

  return (
    <Box
      sx={mergeSx(
        (theme) => ({
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minWidth: 280,
          background: 'inherit',
          flexWrap: scroll ? 'nowrap' : 'wrap',
          overflow: scroll ? 'scroll' : 'auto',
          '::-webkit-scrollbar': { display: 'none' },
          backgroundColor: variant === 'white' ? theme.palette.common.white : 'inherit',
          gap: theme.spacing(0.5),
          [theme.breakpoints.down('md')]: {
            flex: '1',
            overflow: 'auto',
          },
        }),
        sx,
      )}
    >
      {childrenBeforeChips && childrenBeforeChips}
      {hasShowAll && (
        <Chip
          label={SHOW_ALL}
          variant={fillOnSelection && isShowAll ? 'filled' : 'outlined'}
          color={isShowAll ? selectedColor : notSelectedColor}
          onClick={() => onChange('')}
        />
      )}
      {data.map((current) => {
        const currentLabel = labelKey ? current[labelKey] : current;
        const currentValue = valueKey ? current[valueKey] : current;
        return (
          <Chip
            variant={
              fillOnSelection && (value === currentValue || value?.includes(currentValue)) ? 'filled' : 'outlined'
            }
            deleteIcon={showClear ? <MdCancel size={24} /> : undefined}
            onDelete={showClear ? () => onClear(currentValue) : undefined}
            icon={startAdornment ? <>{startAdornment}</> : hasIcon ? <ProductIcon sku={currentLabel} /> : undefined}
            key={currentLabel}
            data-value={currentLabel}
            color={value === currentValue || value?.includes(currentValue) ? selectedColor : notSelectedColor}
            sx={chipSx}
            onClick={() => onChange(currentValue)}
            label={currentLabel}
          />
        );
      })}
    </Box>
  );
};

export default Chips;
