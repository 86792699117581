import { CssBaseline, ThemeProvider, StyledEngineProvider, GlobalStyles } from '@mui/material';
import { ReactNode } from 'react';
import { getURLTheme } from 'services/theming';
import GraphProvider from 'components/GraphProvider';
import { GlobalSubscriptionsProvider } from 'hooks/Subscriptions/useGlobalSubscriptions';
import { FeatureProvider } from 'hooks/useFeatures';
import { SnackbarProvider } from 'hooks/useSnackBar';
import { UserProvider } from 'hooks/useUser';

interface ProvidersProps {
  children: ReactNode;
}

const globalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      [theme.breakpoints.up('md')]: {
        body: {
          overflowY: 'scroll',
        },
        '*::-webkit-scrollbar': {
          width: '6px',
        },
        '*::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.2)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.primary.light,
          borderRadius: '9999px',
        },
      },
    })}
  />
);

const Providers = ({ children }: ProvidersProps) => {
  const theme = getURLTheme();
  return (
    <UserProvider>
      <SnackbarProvider>
        <GraphProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {globalStyles}
              <FeatureProvider>
                <GlobalSubscriptionsProvider>{children}</GlobalSubscriptionsProvider>
              </FeatureProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </GraphProvider>
      </SnackbarProvider>
    </UserProvider>
  );
};

export default Providers;
