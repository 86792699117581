import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import NetworkLoading from 'components/NetworkLoading';
import { useFeaturesQuery } from 'generated/graphql';
import useUser from './useUser';

const FLAGS_STORAGE_KEY = 'flags';

const Context = createContext<{
  flags: { key: string; value: boolean }[];
  flagMap: { [key: string]: boolean };
  isEnabled: (flagName: string) => boolean;
  refetch: () => void;
}>({
  flags: [],
  flagMap: {},
  isEnabled: () => false,
  refetch: () => {},
});

export const FeatureProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();
  const [flags, setFlags] = useState<{ key: string; value: boolean }[]>(() => {
    try {
      const storedFlags = localStorage.getItem(FLAGS_STORAGE_KEY);
      if (storedFlags) {
        return JSON.parse(storedFlags);
      }
      return [];
    } catch {
      return [];
    }
  });
  const [result, refetch] = useFeaturesQuery({ pause: !user });

  useEffect(() => {
    try {
      if (result.data) {
        setFlags(result.data.features.flags);
        localStorage.setItem(FLAGS_STORAGE_KEY, JSON.stringify(result.data.features.flags));
      }
    } catch {}
  }, [result.data]);

  const value = useMemo(
    () => ({
      flags,
      flagMap: flags.reduce((acc, flag) => ({ ...acc, [flag.key]: flag.value }), {}),
      isEnabled: (flagName: string) => flags.some((flag) => flag.key === flagName && flag.value),
      refetch,
    }),
    [flags, refetch],
  );

  const hasNoFlags = !flags || flags.length === 0;

  if (result.fetching && hasNoFlags) {
    return <NetworkLoading bottom={user ? undefined : -5} />;
  }

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useFeatures = () => useContext(Context);
