import { FRESHMARK_ADMIN_PROFILE_ID } from 'services/theming';
import { isFreshmarkEnabled } from 'config/features';
import useUser from './useUser';

const useIsFreshmarkProfile = () => {
  const { user } = useUser();
  return user?.adminProfileId === FRESHMARK_ADMIN_PROFILE_ID && isFreshmarkEnabled;
};

export default useIsFreshmarkProfile;
