import { Box, TableRow } from '@mui/material';
import useIsViewport from 'hooks/useIsViewport';
import { FontStyle, FontType, TableCell } from 'generated/graphql';
import ExpandableTableCell from './ExpandableTableCell';

const ExpandableTableTotals = ({ totals }: { totals: TableCell[] }) => {
  const isMobile = useIsViewport();

  if (!totals || (isMobile && !totals.some((totalCell) => totalCell.mobile))) return null;

  return (
    <TableRow sx={(theme) => ({ background: theme.palette.tertiary?.light })}>
      {totals
        .filter((total) => !isMobile || total.mobile)
        .map((total, i) => (
          <ExpandableTableCell
            key={+i}
            align={total.align.toLowerCase() as 'left' | 'right'}
            sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
          >
            <Box
              component="span"
              sx={(theme) => ({
                ...(total.fontType === FontType.Normal && { fontFamily: theme.typography.fontFamily }),
                ...(total.fontType === FontType.Mono && { fontFamily: 'Roboto Mono' }),
                ...(total.fontStyle === FontStyle.Bold && {
                  fontWeight: theme.typography.fontWeightMedium,
                }),
              })}
            >
              {total.value}
            </Box>
          </ExpandableTableCell>
        ))}
    </TableRow>
  );
};

export default ExpandableTableTotals;
