import { createTheme, responsiveFontSizes } from '@mui/material';
import { baseTheme, BaseTheme } from './base-theme';
import componentThemeOverrides from './component-theme-overrides';

const theme: BaseTheme = createTheme({
  breakpoints: {
    ...baseTheme.breakpoints,
  },
  palette: {
    primary: {
      main: '#056730',
    },
    secondary: {
      main: '#024f25',
    },
    info: {
      main: '#A5CD3A',
    },
    warning: {
      ...baseTheme.palette.warning,
    },
    background: {
      ...baseTheme.palette.background,
    },
    text: {
      ...baseTheme.palette.text,
    },
    tertiary: {
      dark: '#7EB696',
      main: '#B4D8C4',
      light: '#ecf8f2',
    },
  },
  typography: {
    ...baseTheme.typography,
  },
  shape: baseTheme.shape,
});

export const freshmarkTheme = responsiveFontSizes(createTheme(theme, { components: componentThemeOverrides(theme) }));
