import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

const NumberFormatRands = forwardRef(function NumberFormatRands(
  {
    onChange,
    value,
    name,
    ...rest
  }: {
    onChange: (event: { target: { name: string; value: string } }) => void;
    value: number;
    name: string;
  },
  inputRef,
) {
  return (
    <NumericFormat
      {...rest}
      inputMode="decimal"
      pattern="[0-9]*"
      value={value / 100}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: Math.round(Number.parseFloat(values.value) * 100).toString(),
          },
        });
      }}
      decimalScale={2}
      thousandSeparator
      valueIsNumericString
      prefix="R"
    />
  );
});

export default NumberFormatRands;
