const ICON_PREFIX = 'storedImage:';
const PRODUCTS_PREFIX = 'products;';
const PRODUCTS_QUERY_KEY = 'products=';
const FLAG_QUERY_KEY = 'flag=';

export enum IconStatus {
  casesOrdered = 'casesOrdered',
  casesDispatched = 'casesDispatched',
  casesReceived = 'casesReceived',
  casesWarnTime = 'casesWarnTime',
  casesOnHold = 'casesOnHold',
  casesDeviation = 'casesDeviation',
  casesFlag = 'casesFlag',
  unknown = 'unknown',
}

export const getProductImageParts = (value: string) => {
  if (value.startsWith(ICON_PREFIX) || value.startsWith(PRODUCTS_PREFIX + ICON_PREFIX)) {
    const withoutPreix = value.replace(PRODUCTS_PREFIX, '').replace(ICON_PREFIX, '');
    const [status, productsStr, flagStr] = withoutPreix.split('?');
    const products = productsStr.replace(PRODUCTS_QUERY_KEY, '').split(';');
    const flag = flagStr?.replace(FLAG_QUERY_KEY, '');
    return {
      status: status as IconStatus,
      products: products.filter((name) => !!name),
      flag,
    };
  }
  return {
    status: IconStatus.unknown,
    products: value.split(';').filter((name) => !!name),
    flag: false,
  };
};
