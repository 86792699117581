import heicConvert from 'heic-convert/browser';

// Function to sanitize the file type and convert HEIC files to PNG
export const sanitizeFileType = async (rawFile: File) => {
  const oldFileName = rawFile.name;
  // Find the last dot in the file name to get the extension
  let pos = oldFileName.lastIndexOf('.');
  // If there's no dot, the file doesn't have an extension
  const oldExtension = pos !== -1 ? oldFileName.substring(pos) : '';
  if (oldExtension.toLowerCase() === '.heic') {
    try {
      const buffer = new Uint8Array(await rawFile.arrayBuffer());
      const convertedImageBuffer = await heicConvert({
        buffer,
        format: 'PNG',
      });
      const newFileName = `${oldFileName.substring(0, pos)}.png`;
      return new File([convertedImageBuffer], newFileName, {
        type: 'image/png',
      });
    } catch (error) {
      return rawFile;
    }
  }
  return rawFile;
};
