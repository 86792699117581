import { useEffect, useRef } from 'react';

const INITIAL_TIMEOUT = 1000 * 30;
const MAX_TIMEOUT = 1000 * 60 * 15;

/**
 * Invokes a given callback function at an exponentially increasing interval (with a defined maximum limit).
 * The interval begins after an initial delay (default to 30 seconds), and each subsequent call's interval
 * the waiting time will be multiplied by backoff (default is doubling every time), until it reaches the maximum
 * interval (default to 15 minutes). The hook takes care of cleaning up after itself, ensuring the intervals are
 * cleared when the component using the hook is unmounted or when the isEnabled value changes.
 *
 * @param callback - The callback to be executed after each interval.
 * @param isEnabled - A flag that starts or stops the execution of the callback. Default is `true`.
 * @param initial - The initial delay before the first callback call in milliseconds. Default is 30 seconds.
 * @param backoff - The amount by which the interval time is multiplied after each interval. Default is doubling every time.
 * @param shouldReset - A flag to reset the entire backoff and functionality. Default is `false`.
 */
const useBackoffInterval = (
  callback: () => void,
  isEnabled = true,
  initial = INITIAL_TIMEOUT,
  backoff = 2,
  shouldReset = false,
): void => {
  const timeoutIdRef = useRef<number | null>(null);
  const timeoutRef = useRef<number>(initial);

  useEffect(() => {
    if (isEnabled) {
      if (timeoutIdRef.current) {
        window.clearTimeout(timeoutIdRef.current);
      }

      const handleCallback = () => {
        callback();
        timeoutRef.current *= backoff;
        if (timeoutRef.current > MAX_TIMEOUT) timeoutRef.current = MAX_TIMEOUT;
        timeoutIdRef.current = window.setTimeout(handleCallback, timeoutRef.current);
      };

      timeoutIdRef.current = window.setTimeout(handleCallback, timeoutRef.current);
    } else if (shouldReset) {
      // Reset the timeoutRef if shouldReset is true and isEnabled becomes false
      if (timeoutIdRef.current) {
        window.clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
      timeoutRef.current = initial;
    }

    return () => {
      if (timeoutIdRef.current) {
        window.clearTimeout(timeoutIdRef.current);
      }
    };
  }, [isEnabled, callback, backoff, initial, shouldReset]);
};

export default useBackoffInterval;
