import { SliderThumb, Stack, Slider, Typography, SxProps } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { BaseTheme } from 'config/base-theme';
import { SelectOption } from 'generated/graphql';

interface ThumbProps extends React.HTMLAttributes<unknown> {
  labels: string[];
  values: number[];
  'data-index': number;
  sx: SxProps<BaseTheme>;
}

const MIN_SHOW_DIFFERENCE = 10;

/**
 * Determine if the label for a thumb should be displayed based on its current value relative to other values.
 */
const showLabel = (values: number[], index: number) => {
  if (values[index] < MIN_SHOW_DIFFERENCE) return false;
  return values[index] - (values[index - 1] ?? 0) > MIN_SHOW_DIFFERENCE;
};

const Thumb = (props: ThumbProps) => {
  const { children, labels, values, ...rest } = props;
  const index = props['data-index'] ?? 0;
  const isTopItem = index === labels.length - 1;

  const shouldShow = showLabel(values, index);

  return (
    <SliderThumb {...rest} key={index}>
      <Stack>
        {children}
        <Stack direction="row" gap={0.5} sx={{ display: isTopItem ? 'none' : 'auto' }}>
          <span className="horizontal-bar" />
          <Stack direction="column" justifyContent="space-between" sx={{ height: '5px' }}>
            <span className="horizontal-bar-center" />
            <span className="horizontal-bar-center" />
            <span className="horizontal-bar-center" />
          </Stack>
          <span className="horizontal-bar" />
        </Stack>

        <Typography
          sx={(theme) => ({
            display: shouldShow ? 'block' : 'none',
            position: 'absolute',
            top: isTopItem ? theme.spacing(0.5) : theme.spacing(2),
            width: theme.spacing(7),
            left: -20,
            textAlign: 'center',
            fontWeight: 600,
            fontSize: '0.875rem',
            color: theme.palette.common.white,
          })}
          key={`${index}-label`}
        >
          {labels[index]} {values[index] - (values[index - 1] ?? 0)}%
        </Typography>
      </Stack>
    </SliderThumb>
  );
};

const multiSliderStyle = (theme: BaseTheme) => ({
  height: '340px',
  marginBottom: 2,
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15,
    borderRadius: theme.spacing(5),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.light}`,
    '& .horizontal-bar-center': {
      height: '1px',
      width: '5px',
      backgroundColor: 'currentColor',
    },
    '& .horizontal-bar': {
      height: 1.5,
      width: 16,
      backgroundColor: 'currentColor',
      marginTop: 0.2,
    },
    '&:last-child': {
      height: 0,
      width: 0,
      marginLeft: theme.spacing(-1),
      '&:hover': {
        boxShadow: 'none',
      },
    },
  },
  '& .MuiSlider-track': {
    width: 56,
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
  },
  '& .MuiSlider-rail': {
    width: 60,
    backgroundColor: theme.palette.primary.light,
    opacity: 1,
  },
});

const MultiSlider = ({
  selectOptions,
  label,
  onChange,
}: {
  selectOptions: SelectOption[];
  label: string;
  onChange: (values: string) => void;
}) => {
  const [values, setValues] = useState<number[]>(() => {
    let total = 0;
    return selectOptions.map((selectOption: SelectOption, index: number) => {
      if (index === selectOptions.length - 1) {
        return 100;
      }

      total = total + Number(selectOption.value);
      return total;
    });
  });

  const labels = useMemo(() => selectOptions.map((selectOption: SelectOption) => selectOption.label), [selectOptions]);

  useEffect(() => {
    onChange(
      selectOptions
        .map(
          (selectOption: SelectOption, index: number) =>
            `${selectOption.entityID}&${values[index] - (values[index - 1] ?? 0)}`,
        )
        .join(';'),
    );
  }, [values, onChange, selectOptions]);

  const handleChange = (event: any, value: number | number[]) => {
    if (Array.isArray(value)) {
      value[values.length - 1] = 100;
      setValues(value);
      const returnValue = selectOptions
        .map(
          (selectOption: SelectOption, index: number) =>
            `${selectOption.entityID}&${values[index] - (values[index - 1] ?? 0)}`,
        )
        .join(';');
      onChange(returnValue);
    }
  };

  return (
    <Stack alignItems="center">
      <Typography gutterBottom variant="h6">
        {label}
      </Typography>
      <Slider
        sx={(theme) => multiSliderStyle(theme)}
        disableSwap
        slots={{ thumb: Thumb }}
        slotProps={{
          // @ts-ignore
          thumb: { labels: labels, values: values },
        }}
        valueLabelDisplay="auto"
        valueLabelFormat={(value, index) => `${labels[index]}: ${values[index] - (values[index - 1] ?? 0)}%`}
        orientation="vertical"
        value={values}
        onChange={handleChange}
      />
    </Stack>
  );
};

export default MultiSlider;
