import { Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import useOnlineStatus, { OnlineStatus } from 'hooks/useOnlineStatus';

const OnlineStatusAlert = ({ initialStatus = OnlineStatus.Online }: { initialStatus?: OnlineStatus }) => {
  const [currentStatus, setCurrentStatus] = useState<OnlineStatus>(initialStatus);
  const [showStatus, setShowStatus] = useState(false);
  const newStatus = useOnlineStatus();

  useEffect(() => {
    const isGoingOnline = newStatus === OnlineStatus.Online && currentStatus === OnlineStatus.Offline;
    const isGoingOffline = newStatus === OnlineStatus.Offline && currentStatus === OnlineStatus.Online;
    if (isGoingOnline || isGoingOffline) {
      setCurrentStatus(newStatus);
      setShowStatus(true);
    }
  }, [newStatus, currentStatus]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={showStatus}
      autoHideDuration={8000}
      onClose={() => setShowStatus(false)}
    >
      <Alert
        onClose={() => setShowStatus(false)}
        severity={currentStatus === OnlineStatus.Offline ? 'error' : 'success'}
      >
        {currentStatus === OnlineStatus.Offline
          ? 'You are currently offline. Please check your network connection.'
          : 'You back are online and good to go!'}
      </Alert>
    </Snackbar>
  );
};

export default OnlineStatusAlert;
