import { useState, useEffect } from 'react';

const isIOS = (): boolean => {
  return /(iPad|iPhone|iPod|Macintosh)/g.test(navigator.userAgent) && !(window as any).MSStream;
};

const isIOSMobile = (): boolean => {
  return /(iPad|iPhone|iPod)/g.test(navigator.userAgent) && !(window as any).MSStream;
};

const isSafari = (): boolean => {
  const ua = navigator.userAgent.toLowerCase();
  const isSafari = /safari/.test(ua) && !/chrome/.test(ua) && !/android/.test(ua);
  return isSafari;
};

const useDetectDeviceType = (): { isIOS: boolean; isSafari: boolean; isIOSMobile: boolean } => {
  const [deviceInfo, setDeviceInfo] = useState<{ isIOS: boolean; isSafari: boolean; isIOSMobile: boolean }>({
    isIOS: false,
    isSafari: false,
    isIOSMobile: false,
  });

  useEffect(() => {
    const ios = isIOS();
    const safari = ios && isSafari();
    const iosMobile = isIOSMobile();
    setDeviceInfo({ isIOS: ios, isSafari: safari, isIOSMobile: iosMobile });
  }, []);

  return deviceInfo;
};

export default useDetectDeviceType;
