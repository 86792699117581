import { useEffect, useState } from 'react';

const useDebouncedValue = <T>(value: T, delay: number = 500) => {
  const [debouncedValue, setValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
};

export default useDebouncedValue;
