import { DialogContent } from '@mui/material';
import ImageUploadLoader from './ImageUploadLoader';
import ImageUploadPreview from './ImageUploadPreview';

const ImageUploadDialogContent = ({
  files,
  loading,
  compressedFile,
  label = '',
  reference = '',
  onUploadComplete,
  onRemove,
  setActiveImage,
}: {
  files: string[];
  loading: boolean;
  compressedFile: File | null;
  label?: string;
  reference?: string;
  onUploadComplete: (url: string) => void;
  onRemove: (index: number) => void;
  setActiveImage: (activeImage: string) => void;
}) => {
  return (
    <DialogContent sx={(theme) => ({ paddingY: theme.spacing(3) })}>
      {files.map((file, index) => {
        return (
          <ImageUploadPreview
            key={`${file}-${index}`}
            fileSRC={file}
            onRemove={() => onRemove(index)}
            onClick={setActiveImage}
            reference={reference}
          />
        );
      })}
      {loading && compressedFile && (
        <ImageUploadLoader rawFile={compressedFile} onComplete={onUploadComplete} label={label} files={files} />
      )}
    </DialogContent>
  );
};

export default ImageUploadDialogContent;
