import { useCallback } from 'react';
import { logError as logSentryError } from 'services/logging';

export interface SentryContextProps {
  urlOnError: string;
}

const useLogSentryWithContext = () => {
  const logError = useCallback((error: any, context = {}) => {
    const errorContext: SentryContextProps = {
      urlOnError: window.location.href,
    };
    logSentryError(error, { ...errorContext, ...context });
  }, []);

  return logError;
};

export default useLogSentryWithContext;
