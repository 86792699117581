import { createTheme, responsiveFontSizes } from '@mui/material';
import { baseTheme, BaseTheme } from './base-theme';
import componentThemeOverrides from './component-theme-overrides';

const theme: BaseTheme = createTheme({
  breakpoints: {
    ...baseTheme.breakpoints,
  },
  palette: {
    ...baseTheme.palette,
    primary: {
      main: '#264A29',
    },
    secondary: {
      main: '#9DC105',
    },
    info: {
      main: '#ef4b2b',
    },
  },
  typography: {
    ...baseTheme.typography,
  },
  shape: baseTheme.shape,
});

export const inputsTheme = responsiveFontSizes(createTheme(theme, { components: componentThemeOverrides(theme) }));
