import { Box, Dialog, Typography } from '@mui/material';
import Button from 'components/Button';

const BankDepositDetailsDialog = ({ shipmentId, onClose }: { shipmentId: string; onClose: () => void }) => {
  return (
    <Dialog open onClose={onClose}>
      <Box sx={(theme) => ({ padding: theme.spacing(3) })}>
        <Typography sx={(theme) => ({ marginBottom: theme.spacing(2) })} variant="h5">
          Bank details
        </Typography>
        <Typography variant="body1">
          <strong>Name: </strong>
          Nile Fresh (Pty) Ltd
          <br />
          <strong>Bank: </strong>
          First National Bank
          <br />
          <strong>Account: </strong>
          Business Current Account
          <br />
          <strong>Number: </strong>
          62859506225
          <br />
          <strong>Branch code: </strong>
          250655
          {shipmentId && (
            <>
              <br />
              <strong>Reference: </strong>
              <span>
                INV-
                {shipmentId}
              </span>
            </>
          )}
          <br />
          <Box sx={(theme) => ({ marginTop: theme.spacing(2), display: 'flex', justifyContent: 'center' })}>
            <Button variant="contained" color="secondary" onClick={onClose}>
              Close
            </Button>
          </Box>
        </Typography>
      </Box>
    </Dialog>
  );
};

export default BankDepositDetailsDialog;
