import { Box, DialogContent, IconButton, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { extractPathFromUrl } from './ImageUploadField';

const ImageUploadActiveImage = ({
  activeImage,
  setActiveImage,
}: {
  activeImage: string;
  setActiveImage: (activeImage: string) => void;
}) => {
  const [isPDF, setIsPDF] = useState(false);
  useMemo(async () => {
    if (!activeImage) return;
    const fileExtension = extractPathFromUrl(activeImage)?.split('.')[1] ?? '';
    if (fileExtension === 'pdf') setIsPDF(true);
    else setIsPDF(false);
  }, [activeImage]);

  return (
    <DialogContent>
      <Stack direction={'row'} gap={1} sx={{ position: 'relative' }}>
        {isPDF ? (
          <Box component={'iframe'} src={activeImage} width="100%" height="400px" />
        ) : (
          <Box
            component="img"
            src={activeImage}
            loading="eager"
            sx={(theme) => ({
              width: '100%',
              maxHeight: 400,
              objectFit: 'contain',
              borderRadius: theme.spacing(1),
              background: theme.palette.grey[200],
            })}
          />
        )}
        <IconButton
          sx={(theme) => ({
            height: 'fit-content',
            position: 'absolute',
            color: theme.palette.common.white,
            background: theme.palette.primary.light,
            left: '90%',
            top: '2%',
          })}
          onClick={() => setActiveImage('')}
        >
          <MdClose size={14} />
        </IconButton>
      </Stack>
    </DialogContent>
  );
};

export default ImageUploadActiveImage;
