import { ReactNode } from 'react';
import { Outlet } from 'react-router';
import { Permission, Scope, useAccess } from 'hooks/useAccess';

export const RouteAccess = ({
  permission,
  scope,
  children,
}: {
  permission: Permission;
  scope: Scope;
  children?: ReactNode;
}) => {
  const has = useAccess();

  const hasAccess = has(permission, scope);
  return !hasAccess ? null : children ? <>{children}</> : <Outlet />;
};
