import { Button, CircularProgress } from '@mui/material';
import { getPrefixedRoute } from 'services/theming';
import BtnIcon from 'components/BtnIcon';
import DynamicButton from 'components/DynamicButton';
import { useFeatures } from 'hooks/useFeatures';
import useIsViewport from 'hooks/useIsViewport';
import isCustomScheme from 'utils/is-custom-scheme';
import { Button as ButtonType } from 'generated/graphql';

const isSingleActionButton = (button: ButtonType) =>
  button.actions.length === 1 && (!button.actions[0].group || button.actions[0].group?.length === 0);

const ActionCardButton = ({
  button,
  isDisabled,
  eTag = undefined,
  reset,
  onReset,
  onHide = () => {},
  onDisable = () => {},
  isFetching,
  onClick,
  isLoading,
  identifier,
}: {
  button: ButtonType;
  isDisabled: boolean;
  eTag?: string;
  reset: boolean;
  identifier: string;
  onReset: (reset: boolean) => void;
  onHide?: (hide: boolean) => void;
  onDisable?: (disable: boolean) => void;
  isFetching: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  isLoading: boolean;
}) => {
  const { isEnabled } = useFeatures();
  const isOrdersPerformanceEnabled = isEnabled('OrdersPerformance');

  const isMobile = useIsViewport();

  const hasSingleAction = isSingleActionButton(button);
  const isLinkButton = hasSingleAction && Boolean(button.actions[0].url) && !isCustomScheme(button.actions[0].url);

  return (
    <>
      {isLinkButton ? (
        <Button
          size={isMobile ? 'small' : 'medium'}
          variant={button.variant.toLowerCase() as 'text' | 'outlined' | 'contained'}
          color={button.color.toLowerCase() as 'primary' | 'secondary'}
          startIcon={<BtnIcon icon={button.icon} />}
          sx={{ width: '100%' }}
          disabled={isDisabled}
          href={getPrefixedRoute(button.actions[0].url)}
        >
          {button.value.length > 0 ? button.value : button.actions[0]?.value}
        </Button>
      ) : isOrdersPerformanceEnabled && eTag !== undefined ? (
        <DynamicButton
          button={button}
          identifier={identifier}
          eTag={eTag}
          reset={reset}
          onReset={() => onReset?.(false)}
          onHide={onHide}
          onDisable={onDisable}
          size={isMobile ? 'small' : 'medium'}
          startIcon={button.icon && !isLoading ? <BtnIcon icon={button.icon} /> : undefined}
          fetching={isFetching}
          sx={{ width: '100%' }}
          disabled={isDisabled}
          onClick={(e) => onClick(e)}
          isLoading={isLoading}
        >
          {button.value}
        </DynamicButton>
      ) : (
        <Button
          size={isMobile ? 'small' : 'medium'}
          variant={button.variant.toLowerCase() as 'text' | 'outlined' | 'contained'}
          color={button.color.toLowerCase() as 'primary' | 'secondary'}
          startIcon={<BtnIcon icon={button.icon} />}
          sx={{ width: '100%' }}
          disabled={isDisabled}
          onClick={(e) => onClick(e)}
        >
          {button.value}
        </Button>
      )}
      {isLoading && !isOrdersPerformanceEnabled && (
        <CircularProgress
          size={24}
          color={button.color.toLowerCase() as 'primary' | 'secondary'}
          sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: -1.5, marginLeft: -1.5 }}
        />
      )}
    </>
  );
};

export default ActionCardButton;
