import { Box } from '@mui/material';
import { ThemeVariant, getThemeVariant } from 'services/theming';
import useIsVillaProfile from 'hooks/useIsVillaProfile';
import BorderLinearProgress from './BorderLinearProgress';
import Logo from './logo-color.svg?react';
import LogoFreshmark from './logo-freshmark.svg?react';
import LogoVilla from './logo-villa.svg?react';
import HeroInputs from './mobile-loading-hero-inputs.svg?react';
import Hero from './mobile-loading-hero.svg?react';

const NetworkLoading = ({ hideHero = false, bottom = 48 }: { hideHero?: boolean; bottom?: number }) => {
  const themeVariant = getThemeVariant();
  const isVillaProfile = useIsVillaProfile();
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        textAlign: 'center',
        marginTop: theme.spacing(8),
      })}
    >
      {themeVariant === ThemeVariant.FreshMark && <Box component={LogoFreshmark} sx={{ width: 130 }} />}
      {isVillaProfile && <Box component={LogoVilla} sx={{ width: 130 }} />}
      {[ThemeVariant.Nile, ThemeVariant.Inputs].includes(themeVariant) && !isVillaProfile && (
        <Box component={Logo} sx={{ maxHeight: 80, maxWidth: 100 }} />
      )}
      <Box
        sx={(theme) => ({
          maxWidth: '200px',
          textAlign: 'center',
          margin: theme.spacing(4, 'auto'),
        })}
      >
        <BorderLinearProgress variant="indeterminate" sx={{ height: 10 }} />
      </Box>
      {!hideHero && [ThemeVariant.Nile, ThemeVariant.Inputs].includes(themeVariant) && (
        <Box
          sx={(theme) => ({
            backgroundPosition: 'right bottom',
            position: 'absolute',
            left: 0,
            bottom: bottom,
            width: '100%',
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },
          })}
        >
          {ThemeVariant.Inputs === themeVariant ? <HeroInputs /> : <Hero />}
        </Box>
      )}
    </Box>
  );
};

export default NetworkLoading;
