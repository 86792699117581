import { useEffect } from 'react';
import { useSeeShipmentMutation } from 'generated/graphql';

const DELAY = 2000;

const useSeeShipment = (id: string) => {
  const [, seeShipment] = useSeeShipmentMutation();
  useEffect(() => {
    if (!id) return;
    const timeout = setTimeout(() => {
      seeShipment({
        input: {
          ID: id,
        },
      });
    }, DELAY);
    return () => {
      clearTimeout(timeout);
    };
  }, [id, seeShipment]);
};

export default useSeeShipment;
