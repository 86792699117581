import { Box, Typography } from '@mui/material';
import { useRef, ElementRef, useCallback } from 'react';
import { MdAddCircle } from 'react-icons/md';
import Button from 'components/Button';
import { useFeatures } from 'hooks/useFeatures';
import useFileCompression from 'hooks/useFileCompression';
import { sanitizeFileType } from 'utils/sanitizeFileType';
import { FormType } from 'generated/graphql';
import DragableFileEdits from './DragableFileEdits';
import { FileEdit, FileUploadPreview } from './FileEdit';

const ACCEPTED_FILE_TYPES = 'image/*, .heic, application/pdf';

const FileUploader = ({
  label,
  files = [],
  onChange,
  isQCStorage = false,
  formType,
}: {
  label: string;
  files?: string[];
  onChange: (urls: string[]) => void;
  isQCStorage?: boolean;
  formType: FormType;
}) => {
  const { isEnabled } = useFeatures();
  const isOpsPolishEnabled = isEnabled('OpsPolish');
  const inputRef = useRef<ElementRef<'input'>>(null);
  const [compressedFile, compressFile] = useFileCompression();

  const handleFileUpload = async (event: any) => {
    if (!event.target.files || !event.target.files.length) {
      compressFile(null);
      return;
    }
    const sanitizedValue = await sanitizeFileType(event.target.files[0]);
    compressFile(sanitizedValue);
  };

  const handleAddClicked = () => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.click();
  };

  const handleAddComplete = useCallback(
    (url: string) => {
      onChange([...files, url]);
      compressFile(null);
    },
    [files, onChange, compressFile],
  );

  const handleChange = (newSrc: string, oldSrc: string) => {
    onChange([...files.map((src) => (src === oldSrc ? newSrc : src))]);
  };

  const handleRemove = (removedUrl: string) => {
    onChange([...files.filter((src) => src !== removedUrl)]);
  };

  const handleDragAndDrop = (newOrder: string[]) => {
    onChange(newOrder);
  };

  return (
    <Box sx={(theme) => ({ marginBottom: theme.spacing(2) })}>
      <input
        accept={ACCEPTED_FILE_TYPES}
        type="file"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        ref={inputRef}
      />
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>

      {isOpsPolishEnabled ? (
        <DragableFileEdits
          children={files}
          onChange={handleChange}
          onRemove={handleRemove}
          onDragAndDrop={handleDragAndDrop}
          compressedFile={compressedFile}
          onComplete={handleAddComplete}
          isQCStorage={isQCStorage}
        />
      ) : (
        <>
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            sx={(theme) => ({
              maxWidth: 348,
              gap: theme.spacing(1),
            })}
          >
            {files.map((src) => (
              <FileEdit
                key={src}
                src={src}
                onChange={(newUrl) => handleChange(newUrl, src)}
                onRemove={() => handleRemove(src)}
              />
            ))}
          </Box>
          {compressedFile && <FileUploadPreview rawFile={compressedFile} onComplete={handleAddComplete} />}
        </>
      )}
      {![FormType.ShipmentReceiveWithNotes].includes(formType) && (
        <Button variant="outlined" onClick={handleAddClicked} startIcon={<MdAddCircle size={20} />}>
          Add file
        </Button>
      )}
    </Box>
  );
};

export default FileUploader;
