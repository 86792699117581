import { Box, TableCell, TableRow } from '@mui/material';
import { BaseTheme } from 'config/base-theme';
import useIsViewport from 'hooks/useIsViewport';
import { Row } from 'generated/graphql';

const tableCellStyle = (theme: BaseTheme) => ({
  padding: '4px 10px',
  fontFamily: theme.typography.fontFamily,
  backgroundColor: theme.palette.background.paper,
});

const mobileRowInfoStyle = (theme: BaseTheme) => ({
  padding: theme.spacing(0.25, 0),
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
});

interface EditHistoryRowDetailProps {
  data: any;
  refresh(): void;
}

const EditHistoryRowDetail = ({ data }: EditHistoryRowDetailProps) => {
  const isMobile = useIsViewport();

  if (!data) return null;

  const rows = data.data.itemRows;

  return (
    <>
      {rows?.map((row: Row, i: number) =>
        isMobile ? (
          <TableRow key={i}>
            <TableCell colSpan={5} key={`rowCell${i}`} align={'left'} sx={tableCellStyle}>
              <div>
                <Box sx={mobileRowInfoStyle}>
                  <b>Description:</b> {data.cells[1]?.value}
                </Box>
                <Box sx={mobileRowInfoStyle}>
                  <b>Cases:</b> {row?.cells[4]?.value}
                </Box>
                <Box sx={mobileRowInfoStyle}>
                  <b>Units:</b> {row?.cells[5]?.value}
                </Box>
              </div>
            </TableCell>
          </TableRow>
        ) : (
          <TableRow key={i}>
            {row?.cells.map((cellData, j) => (
              <TableCell
                key={`${cellData?.value + j}`}
                align={cellData?.align.toLowerCase() as 'left' | 'right'}
                sx={tableCellStyle}
              >
                {cellData?.value}
              </TableCell>
            ))}
          </TableRow>
        ),
      )}
    </>
  );
};

export default EditHistoryRowDetail;
