import { getPrefixedRoute } from 'services/theming';

export const paths = {
  login: () => getPrefixedRoute('/login'),
  emailNotifications: () => getPrefixedRoute('/notifications/email'),
  pushNotifications: () => getPrefixedRoute('/notifications/push'),
  explore: () => getPrefixedRoute('/explore'),
  shipments: () => getPrefixedRoute('/shipments'),
  market: () => getPrefixedRoute('/market'),
  marketHome: () => getPrefixedRoute('/market/home'),
  // TODO(tiaan): This route should be changed to /market/seller
  sellerMarket: () => getPrefixedRoute('/seller/products'),
  // TODO(tiaan): This route should be changed to /cart
  cart: () => getPrefixedRoute('/checkout'),
  profile: () => getPrefixedRoute('/profile'),
  reports: () => getPrefixedRoute('/reports'),
  customers: () => getPrefixedRoute('/customers?t=0&g=2'),
  // TODO(tiaan): This route should be changed to /logistics
  logistics: () => getPrefixedRoute('/seller-locations'),
  marketing: () => getPrefixedRoute('/profile/marketing'),
  editProfile: (profileId?: number) => getPrefixedRoute(`/edit/profile/${profileId}`),
  userSettings: () => getPrefixedRoute('/profile/user'),
};
